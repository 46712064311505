import { LocationDetails, DealPlans } from "./deal";

export interface PreApplyDetails {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  location: LocationDetails;
  birthDate?: Date;
  SSN?: string;
}

export type Application = PreApplyDetails & {
  proposalId: string;
  selectedPlan: DealPlans;
  downPayment: number;
  addBattery: boolean;
};

export enum PRE_QUALIFICATION_NEXT_STEPS {
  APPLY = "apply",
  CONTACT_INSTALLER = "contact-installer",
  NOT_VERIFIED = "not-verified",
  NOT_QUALIFIED = "not-qualified",
  NOT_FOUND = "not-found",
}
