import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import {
  RecaptchaVerifier,
  ConfirmationResult,
  NextOrObserver,
  Unsubscribe as AuthUnsubscribe,
  getAuth,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signOut,
  User,
} from "firebase/auth";

import firebaseConfig from "./firebaseServiceConfig";
import { Installer } from "../types/installers";
import {
  LoanDetails,
  PublicProposal,
  SunlightDisclosure,
} from "../types/proposal";
import { AppSettings, UsaState } from "../types/app-settings";
import {
  Application,
  PreApplyDetails,
  PRE_QUALIFICATION_NEXT_STEPS,
} from "../types/application";
import { CalculatedPlansAndSavings } from "../types/plans";
import { SetterOrUpdater } from "recoil";
import { Prospect } from "../types/prospect";
import { SalesRep } from "../types/salesRep";

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);

if (process.env.NODE_ENV === "development") {
  console.warn(`Running in ${process.env.NODE_ENV} environment`);
  // connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
  // connectFirestoreEmulator(firebaseFirestore, "localhost", 5002);
}

// Auth
let appVerifier: RecaptchaVerifier | null = null;
const getAppVerifier = () => {
  if (!appVerifier) {
    try {
      appVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible", badge: "bottomleft" },
        firebaseAuth
      );
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = appVerifier;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return appVerifier;
};

export const signUserWithPhoneNumber = async (
  phoneNumber: string
): Promise<ConfirmationResult> => {
  const appVerifier = getAppVerifier();
  if (!appVerifier) {
    throw new Error("Could not create captcha verifier");
  }
  const confirmationResult = await signInWithPhoneNumber(
    firebaseAuth,
    phoneNumber,
    appVerifier
  );
  window.confirmationResult = confirmationResult;
  return confirmationResult;
};

export const signUserOut = async (
  setSignedInUser: SetterOrUpdater<Prospect | null>
): Promise<void> => {
  await signOut(firebaseAuth);
  setSignedInUser(null);
};

export const onAuthStateChangedHook = (
  observer: NextOrObserver<User>
): AuthUnsubscribe => onAuthStateChanged(firebaseAuth, observer);

// Firestore
const installersListCollection = collection(
  firebaseFirestore,
  "installersList"
);
const proposalsCollection = collection(firebaseFirestore, "proposals");
const salesRepCollection = collection(firebaseFirestore, "salesReps");
const statesCollection = collection(firebaseFirestore, "states");
const configurationCollection = collection(firebaseFirestore, "configuration");

export const getInstallerFromList = async (
  id: string
): Promise<Installer | null> => {
  const installer = (
    await getDoc(doc(installersListCollection, id))
  ).data() as Installer | null;
  return installer;
};

export const getProposal = async (
  id: string
): Promise<PublicProposal | null> => {
  const proposal = (
    await getDoc(doc(proposalsCollection, id))
  ).data() as PublicProposal | null;
  return proposal;
};

export const getAppSettings = async (): Promise<AppSettings | null> => {
  const appSettings = (
    await getDoc(doc(configurationCollection, "appSettings"))
  ).data() as AppSettings | null;
  return appSettings;
};

export const getStates = async (): Promise<UsaState[] | null> => {
  const states: UsaState[] = (await getDocs(statesCollection)).docs.map(
    (doc) => {
      const { name, enabled, leaseEnabled, incentive } = doc.data() as UsaState;

      return {
        abbreviation: doc.id,
        name,
        enabled,
        leaseEnabled,
        incentive,
      };
    }
  );

  return states;
};

// --------------------------------------------------------
// Functions
// --------------------------------------------------------

const getSalesRepFunction = httpsCallable(firebaseFunctions, "getSalesRep");

export const getSalesRepById = async (id: string): Promise<SalesRep> => {
  const { data } = await getSalesRepFunction({ salesRepId: id });

  if (!data) {
    throw new Error("Error getting sales rep");
  }

  const { result } = data as { result: SalesRep };
  return result;
};

const getDownPaymentCalculationsFunction = httpsCallable(
  firebaseFunctions,
  "getDownPaymentCalculations"
);
export const getDownPaymentCalculations = async ({
  proposalId,
  preApplyDetails,
}: {
  proposalId: string;
  preApplyDetails?: PreApplyDetails;
}): Promise<{
  result: {
    nextStep: PRE_QUALIFICATION_NEXT_STEPS;
    downPaymentMinimum: number;
    plansAndSavings?: CalculatedPlansAndSavings[];
  };
  status: number;
}> => {
  const { data } = await getDownPaymentCalculationsFunction(
    preApplyDetails ? { proposalId, preApplyDetails } : { proposalId }
  );
  if (!data) {
    throw new Error("Error getting down payment calculations");
  }
  return data as {
    result: {
      nextStep: PRE_QUALIFICATION_NEXT_STEPS;
      downPaymentMinimum: number;
      plansAndSavings?: CalculatedPlansAndSavings[];
    };
    status: number;
  };
};

const sendApplicationFunction = httpsCallable(firebaseFunctions, "apply");
export const sendApplication = async (
  application: Application
): Promise<{ result?: { application: Application }; status: number }> => {
  const { data } = await sendApplicationFunction({ application });
  if (!data) {
    throw new Error("Error applying");
  }
  return data as { result?: { application: Application }; status: number };
};

const getStripeSetupIntentFunction = httpsCallable(
  firebaseFunctions,
  "getStripeSetupIntent"
);

export const getStripeSetupIntent = async (
  price: number
): Promise<{ client_secret: string }> => {
  const { data } = await getStripeSetupIntentFunction({ price });
  if (!data) {
    throw new Error("Error getting setup intent");
  }
  const { result } = data as { result: { client_secret: string } };
  return result;
};

const attachProposalToProspectFunction = httpsCallable(
  firebaseFunctions,
  "attachProposalToProspect"
);
export const attachProposalToProspect = async ({
  proposalId,
  preApplyDetails,
}: {
  proposalId: string;
  preApplyDetails: PreApplyDetails;
}): Promise<{ result?: { proposal: PublicProposal }; status: number }> => {
  const { data } = await attachProposalToProspectFunction({
    proposalId,
    preApplyDetails,
  });
  if (!data) {
    throw new Error("Error attaching proposal to prospect");
  }
  return data as { result?: { proposal: PublicProposal }; status: number };
};

const getLoanCalculationFunction = httpsCallable(
  firebaseFunctions,
  "getLoanCalculation"
);
export const getLoanCalculation = async (args: {
  proposalId: string;
  downPaymentPercent: number;
  term: number;
}): Promise<{
  result?: { loan: LoanDetails };
  status: number;
}> => {
  const { data } = await getLoanCalculationFunction(args);
  if (!data) {
    throw new Error("Error getting loan calculation");
  }
  return data as { result?: { loan: LoanDetails }; status: number };
};

const getLoanDisclosuresFunction = httpsCallable(
  firebaseFunctions,
  "getLoanDisclosures"
);
export const getLoanDisclosures = async (args: {
  proposalId: string;
}): Promise<{
  result?: { disclosures: SunlightDisclosure[] };
  status: number;
}> => {
  const { data } = await getLoanDisclosuresFunction(args);
  if (!data) {
    throw new Error("Error getting loan disclosures");
  }
  return data as {
    result?: { disclosures: SunlightDisclosure[] };
    status: number;
  };
};

const unlockLoansFunction = httpsCallable(firebaseFunctions, "unlockLoans");
export const unlockLoans = async (args: {
  proposalId: string;
  birthDate: string;
  ssn: string;
  annualIncome: number;
}): Promise<{
  result: { isSunlightPreQualified: boolean };
  status: number;
}> => {
  const { data } = await unlockLoansFunction(args);
  if (!data) {
    throw new Error("Error unlocking loans");
  }
  return data as {
    result: { isSunlightPreQualified: boolean };
    status: number;
  };
};

const getLoanCreditUrlFunction = httpsCallable(
  firebaseFunctions,
  "getLoanCreditUrl"
);
export const getLoanCreditUrl = async (args: {
  proposalId: string;
}): Promise<{
  result?: { creditUrl: string };
  status: number;
}> => {
  const { data } = await getLoanCreditUrlFunction(args);
  if (!data) {
    throw new Error("Error getting loan credit url");
  }
  return data as {
    result?: { creditUrl: string };
    status: number;
  };
};

const getFormulaParamFunction = httpsCallable(
  firebaseFunctions,
  "getFormulaParam"
);
export const getFormulaParam = async (param: string): Promise<number> => {
  const { data } = (await getFormulaParamFunction({ param })) as {
    data: { result?: { value: number }; status: number };
  };
  if (!data || data?.status !== 200) {
    throw new Error(`Error getting formula param ${param}`);
  }
  return data.result?.value || 0;
};
