import React, { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";

import logo from "../../assets/images/logo.svg";
import { signedInUserAtom } from "../../state/atoms/auth";
// import profileIcon from "../../assets/images/profile-icon.svg";

import { contextProposalAtom } from "../../state/atoms/global";

const Header: FunctionComponent<{}> = ({ children }) => {
  const proposal = useRecoilValue(contextProposalAtom);
  const signedInUser = useRecoilValue(signedInUserAtom);

  const { prospect } = proposal || {};

  return (
    <section
      id="section-header"
      className="container-lg px-3 px-lg-0 mb-1 mt-2 mt-lg-2 position-relative"
    >
      <div className="row">
        <div className="col-6 pl-0 pl-lg-1 pr-0">
          <div>
            <img className="logo" src={logo} alt="Rhino-Eco" />
          </div>
        </div>
        <div className="col-6 px-0 text-right d-flex justify-content-end">
          {prospect?.firstName && prospect?.lastName && (
            <div className="user-select-none d-flex align-items-center pb-2">
              <span
                className={`mr-1 rounded-circle ${
                  signedInUser ? "bg-success" : "bg-brand-primary"
                }`}
                style={{ width: "0.6rem", height: "0.6rem" }}
              />
              <span className="font-weight-500">
                {prospect?.firstName} {prospect?.lastName}
              </span>
            </div>
          )}
        </div>
      </div>
      {children}
    </section>
  );
};

export default Header;
