export interface Plans {
  systemCost: number;
  fixed: number;
  payNow: number;
  payLater: number;
}

export interface PlanSaving {
  percentage: number;
  lifeTimeSavings: number;
}

export interface PlansSaving {
  fixed: PlanSaving;
  payNow: PlanSaving;
  payLater: PlanSaving;
}

export interface PlansData {
  calculatedPlans: Plans;
  savingDetails: PlansSaving;
}

export interface CalculatedPlansAndSavings {
  downPaymentPercent: number;
  calculatedPlans: Plans;
  savingDetails: PlansSaving;
}

export enum LOAN_TERMS {
  "L12" = "12",
  "L15" = "15",
  "L20" = "20",
  "L25" = "25",
}
