import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import phoneIcon from "../../assets/images/phone-icon.svg";
import {
  attachProposalToProspect,
  signUserWithPhoneNumber,
} from "../../services/firebase";
import { signedInUserAtom } from "../../state/atoms/auth";
import {
  customerDetailsAddressAtom,
  customerDetailsCityAtom,
  customerDetailsEmailAtom,
  customerDetailsFirstNameAtom,
  customerDetailsLastNameAtom,
  customerDetailsPhoneNumberAtom,
  customerDetailsStateAtom,
  customerDetailsZipAtom,
} from "../../state/atoms/form";
import {
  confirmationResultAtom,
  contextProposalAtom,
  contextProposalIdAtom,
  hasOtpBeenSentAtom,
} from "../../state/atoms/global";
import { toastDataAtom } from "../../state/atoms/ui";
import { Prospect } from "../../types/prospect";
import { Loading } from "../Loading";
import { ToastType } from "../Toast";

const OtpScreen: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);

  const [hasOtpBeenSent, setHasOtpBeenSent] =
    useRecoilState(hasOtpBeenSentAtom);
  const [contextProposal, setContextProposal] =
    useRecoilState(contextProposalAtom);
  const contextProposalId = useRecoilValue(contextProposalIdAtom);
  const [confirmationResult, setConfirmationResult] = useRecoilState(
    confirmationResultAtom
  );
  const customerDetailsFirstName = useRecoilValue(customerDetailsFirstNameAtom);
  const customerDetailsLastName = useRecoilValue(customerDetailsLastNameAtom);
  const customerDetailsEmail = useRecoilValue(customerDetailsEmailAtom);
  const customerDetailsPhoneNumber = useRecoilValue(
    customerDetailsPhoneNumberAtom
  );
  const customerDetailsAddress = useRecoilValue(customerDetailsAddressAtom);
  const customerDetailsCity = useRecoilValue(customerDetailsCityAtom);
  const customerDetailsState = useRecoilValue(customerDetailsStateAtom);
  const customerDetailsZip = useRecoilValue(customerDetailsZipAtom);

  const [code, setCode] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [hadErrors, setHadErrors] = useState(false);

  const [toastData, setToastData] = useRecoilState(toastDataAtom);

  if (signedInUser) {
    history.push(`/step-1${search}`);
  }

  if (!contextProposal || !contextProposalId) {
    history.push(`/something-went-wrong${search}`);
    return null;
  }

  const { prospect } = contextProposal;

  const {
    phoneNumber = "",
    firstName = "",
    lastName = "",
    email = "",
  } = prospect || {};

  if (!phoneNumber) {
    setToastData([
      ...toastData,
      {
        toastType: ToastType.info,
        header: "Missing phone number in proposal",
        body: "Please contact your installer to update your proposal",
      },
    ]);
    return null;
  }

  const sendOtp = async () => {
    try {
      setHasOtpBeenSent(true);
      const confirmationResult = await signUserWithPhoneNumber(phoneNumber);
      setConfirmationResult(confirmationResult);
    } catch (error) {
      console.error("Error sending code", error);
    }
  };

  useEffect(() => {
    if (!hasOtpBeenSent) sendOtp();
  }, []);

  useEffect(() => {
    if (isErrored) {
      setHadErrors(true);
    }
  }, [isErrored]);

  const signUserWithOtp = async (
    e: { preventDefault: () => void },
    code: string
  ) => {
    e.preventDefault();
    if (confirmationResult) {
      setIsProcessing(true);
      try {
        const codeResult = await confirmationResult.confirm(code);
        if (codeResult.user) {
          const {
            user: { uid },
          } = codeResult;
          const signedInProspect: Prospect = {
            uid,
            firstName,
            lastName,
            email,
            phoneNumber,
          };
          const { prospect } = contextProposal;
          const { uid: proposalProspectUid } = prospect || {};
          if (!proposalProspectUid) {
            const { result, status } = await attachProposalToProspect({
              proposalId: contextProposalId,
              preApplyDetails: {
                email: customerDetailsEmail || "",
                phoneNumber: customerDetailsPhoneNumber || "",
                firstName: customerDetailsFirstName || "",
                lastName: customerDetailsLastName || "",
                location: {
                  address: customerDetailsAddress || "",
                  city: customerDetailsCity || "",
                  state: customerDetailsState || "",
                  zip: customerDetailsZip || "",
                },
              },
            });
            if (!result?.proposal?.prospect?.uid || status !== 200) {
              throw new Error("Error attaching proposal to prospect");
            }
            const { prospect: updatedProspect } = result.proposal;
            setContextProposal({
              ...contextProposal,
              prospect: updatedProspect,
            });
          }

          setSignedInUser(signedInProspect);
        } else {
          setIsErrored(true);
          setToastData([
            ...toastData,
            {
              toastType: ToastType.info,
              header: "Incorrect code",
            },
          ]);
        }
      } catch (error) {
        setIsErrored(true);
        setToastData([
          ...toastData,
          {
            toastType: ToastType.info,
            header: "Something went wrong",
          },
        ]);
        console.error("Error signing user with OTP", error);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  return !confirmationResult ? (
    <Loading />
  ) : (
    <div className="container-sm text-center m-auto">
      <form onSubmit={(e) => signUserWithOtp(e, code)}>
        <div
          className="card card-body mt-4 mb-2 p-4 rounded m-auto"
          style={{ maxWidth: "30rem" }}
        >
          <div className="m-auto mb-2 rounded-circle bg-tertiary p-1 d-flex justify-content-center align-items-center">
            <div className="aspect-ratio-1-1">
              <img
                src={phoneIcon}
                alt=""
                style={{ width: "1.5rem", maxHeight: "100%", maxWidth: "100%" }}
              />
            </div>
          </div>
          <h5 className="font-weight-600">Just making sure it&apos;s you</h5>
          <div className="mt-2">
            Please enter the 6-digit code
            <br />
            sent you to
            <span className="ml-05">
              {phoneNumber.substring(0, 5) +
                "****" +
                phoneNumber.substring(
                  phoneNumber.length - 4,
                  phoneNumber.length
                )}
            </span>
          </div>
          <div className="mt-2">
            <input
              type="text"
              className="form-control otp-input m-auto"
              placeholder="XXX-XXX"
              maxLength={7}
              value={code.replace(/([0-9]{3})([0-9]{1,3})/, "$1-$2")}
              onChange={(e) => {
                setIsErrored(false);
                const newCode = e.target.value.replaceAll("-", "");
                setCode(newCode);
                if (newCode.length === 6 && !isProcessing && !hadErrors) {
                  signUserWithOtp(e, newCode);
                }
              }}
            />
            {isErrored && (
              <div className="pt-1 text-primary text-medium">
                Incorrect code
              </div>
            )}
          </div>
          <div className={isErrored ? "mt-2" : "mt-5"}>
            <button
              className="btn btn-primary w-50 btn-block"
              onClick={(e) => signUserWithOtp(e, code)}
              disabled={!code || isProcessing}
            >
              Verify
            </button>
          </div>
        </div>
        <div className="mt-2 text-medium">
          Didn&apos;t get the code?
          <span className="link ml-05" role="button" onClick={sendOtp}>
            Resend code
          </span>
        </div>
      </form>
    </div>
  );
};

export default OtpScreen;
