import { Deal } from "./deal";
import { Installer } from "./installers";
import { BatteryCost } from "./battery";
import { Plans, PlanSaving, PlansSaving } from "./plans";
import { Prospect } from "./prospect";
import { UID } from "./common";
import { TagType } from "../components/Tag";

export interface Proposal {
  deal: Deal;
  installer: Installer;
  prospect?: Prospect;
  salesRepId?: UID;
  isSunlightPreQualified?: boolean;
}

export type SelectedPlansToDisplay = {
  [k in TagType]: boolean;
};

export interface PublicProposal {
  calculatedBattery: BatteryCost;
  installer: Installer;
  calculatedPlans: Plans;
  savingDetails: PlansSaving;
  prospect?: Prospect;
  salesRepId?: UID;
  isSunlightPreQualified?: boolean;
  deal: Deal;
  selectedPlansToDisplay: SelectedPlansToDisplay;
}

export interface LoanDetails {
  apr: number;
  monthlyPayment: number;
  escalatedMonthlyPayment: number;
  paydownPercentage: number;
  loanAmount: number;
  saving: PlanSaving;
}

export enum SunlightDisclosureType {
  Income = "Income",
  IncomeTT = "IncomeTT",
  SubmitForPrequal = "SubmitForPrequal",
  eConsent = "eConsent",
  PhoneNumberSolar = "PhoneNumberSolar",
  ESignSubmit = "ESignSubmit",
}

export interface SunlightDisclosure {
  type: SunlightDisclosureType;
  isHome: boolean;
  isSolar: boolean;
  disclosureText: string;
  spanishDisclosureText: string;
}
