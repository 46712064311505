import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import {
  RecoilRoot,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import {
  getAppSettings,
  getDownPaymentCalculations,
  getProposal,
  getStates,
  onAuthStateChangedHook,
  signUserOut,
} from "./services/firebase";
import {
  appSettingsAtom,
  contextProposalAtom,
  contextProposalIdAtom,
  contextStateIncentiveAtom,
  downpaymentCalculationsAtom,
  downPaymentMinimumPercentAtom,
  enabledStatesAtom,
} from "./state/atoms/global";
import { signedInUserAtom } from "./state/atoms/auth";
import {
  isModalOpenAtom,
  modalContentAtom,
  toastDataAtom,
  isAppLoadingAtom,
} from "./state/atoms/ui";

import Header from "./components/sections/Header";
import Stepper from "./components/sections/Stepper";
import Footer from "./components/sections/Footer";
import PageNotFound from "./components/PageNotFound";
import Steps from "./components/steps/Steps";
import Success from "./components/Success";
import ContactInstaller from "./components/ContactInstaller";
import StartFresh from "./components/StartFresh";
import StartSplash from "./components/StartSplash";
import { Toast, ToastType } from "./components/Toast";
import OtpScreen from "./components/auth/OtpScreen";
import { Loading } from "./components/Loading";
import { Modal } from "./components/Modal";
import SuccessSunlight from "./components/SuccessSunlight";
import {
  customerDetailsFirstNameAtom,
  customerDetailsLastNameAtom,
  customerDetailsEmailAtom,
  customerDetailsPhoneNumberAtom,
  customerDetailsAddressAtom,
  customerDetailsCityAtom,
  customerDetailsStateAtom,
  customerDetailsZipAtom,
} from "./state/atoms/form";
import NoPlansFound from "./components/steps/NoPlansFound";

const WrappedApp: FunctionComponent<{}> = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const [toastData, setToastData] = useRecoilState(toastDataAtom);

  const [contextProposalId, setContextProposalId] = useRecoilState(
    contextProposalIdAtom
  );
  const [contextProposal, setContextProposal] =
    useRecoilState(contextProposalAtom);

  const { prospect } = contextProposal || {};
  const {
    firstName: proposalFirstName,
    lastName: proposalLastName,
    email: proposalEmail,
    phoneNumber: proposalPhoneNumber,
    location,
  } = prospect || {};
  const {
    address: proposalAddress,
    city: proposalCity,
    state: proposalState,
    zip: proposalZip,
  } = location || {};

  const setDownPaymentMinimumPercent = useSetRecoilState(
    downPaymentMinimumPercentAtom
  );
  const setDownpaymentCalculations = useSetRecoilState(
    downpaymentCalculationsAtom
  );
  const setAppSettings = useSetRecoilState(appSettingsAtom);
  const setEnabledStates = useSetRecoilState(enabledStatesAtom);
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const setModalContent = useSetRecoilState(modalContentAtom);
  const setStateIncentive = useSetRecoilState(contextStateIncentiveAtom);

  const isAppLoading = useRecoilValue(isAppLoadingAtom);
  const [isLoadingProposal, setIsLoadingProposal] = useState(true);
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);
  const [isWaitingForAuth, setIsWaitingForAuth] = useState(true);

  const queryParams = new URLSearchParams(search);
  const proposalId =
    queryParams.get("proposalId") || queryParams.get("proposalid");

  const [customerDetailsFirstName, setCustomerDetailsFirstName] =
    useRecoilState(customerDetailsFirstNameAtom);
  const [customerDetailsLastName, setCustomerDetailsLastName] = useRecoilState(
    customerDetailsLastNameAtom
  );
  const [customerDetailsEmail, setCustomerDetailsEmail] = useRecoilState(
    customerDetailsEmailAtom
  );
  const [customerDetailsPhoneNumber, setCustomerDetailsPhoneNumber] =
    useRecoilState(customerDetailsPhoneNumberAtom);
  const [customerDetailsAddress, setCustomerDetailsAddress] = useRecoilState(
    customerDetailsAddressAtom
  );
  const [customerDetailsCity, setCustomerDetailsCity] = useRecoilState(
    customerDetailsCityAtom
  );
  const [customerDetailsState, setCustomerDetailsState] = useRecoilState(
    customerDetailsStateAtom
  );
  const [customerDetailsZip, setCustomerDetailsZip] = useRecoilState(
    customerDetailsZipAtom
  );

  useEffect(() => {
    const initProposal = async () => {
      try {
        if (proposalId) {
          setIsLoadingProposal(true);
          const proposal = await getProposal(proposalId);
          if (proposal) {
            setContextProposalId(proposalId);
            setContextProposal(proposal);
            setIsLoadingProposal(false);

            const states = await getStates();
            const proposalState = states?.find(
              (s) => s.abbreviation === proposal.deal.systemLocation?.state
            );
            setStateIncentive(proposalState?.incentive || 0);

            if (pathname === "/something-went-wrong") {
              history.push(`/go-solar${search}`);
            }
          } else {
            setToastData([
              ...toastData,
              {
                toastType: ToastType.info,
                header: "Proposal not found",
              },
            ]);
            history.push(`/something-went-wrong${search}`);
          }
        } else {
          setToastData([
            ...toastData,
            {
              toastType: ToastType.info,
              header: "Proposal ID is missing",
            },
          ]);
          history.push(`/something-went-wrong${search}`);
        }
      } catch (err) {
        setToastData([
          ...toastData,
          {
            toastType: ToastType.info,
            header: "Something went wrong",
          },
        ]);
        console.error(`Error: ${JSON.stringify(err)}`);
        history.push(`/something-went-wrong${search}`);
      } finally {
        setIsLoadingProposal(false);
      }
    };
    initProposal();
  }, [proposalId]);

  useEffect(() => {
    const checkProposalOwnership = async () => {
      if (signedInUser && contextProposal) {
        if (
          signedInUser.phoneNumber !== contextProposal.prospect?.phoneNumber
        ) {
          await signUserOut(setSignedInUser);
        }
      }
    };
    checkProposalOwnership();
  }, [signedInUser, contextProposal]);

  useEffect(() => {
    if (!customerDetailsFirstName && !!proposalFirstName) {
      setCustomerDetailsFirstName(proposalFirstName);
    }
    if (!customerDetailsLastName && !!proposalLastName) {
      setCustomerDetailsLastName(proposalLastName);
    }
    if (!customerDetailsEmail && !!proposalEmail) {
      setCustomerDetailsEmail(proposalEmail);
    }
    if (!customerDetailsPhoneNumber && !!proposalPhoneNumber) {
      setCustomerDetailsPhoneNumber(proposalPhoneNumber);
    }
    if (!customerDetailsAddress && !!proposalAddress) {
      setCustomerDetailsAddress(proposalAddress);
    }
    if (!customerDetailsCity && !!proposalCity) {
      setCustomerDetailsCity(proposalCity);
    }
    if (!customerDetailsState && !!proposalState) {
      setCustomerDetailsState(proposalState);
    }
    if (!customerDetailsZip && !!proposalZip) {
      setCustomerDetailsZip(proposalZip);
    }
  }, [
    proposalFirstName,
    proposalLastName,
    proposalEmail,
    proposalAddress,
    proposalCity,
    proposalState,
    proposalZip,
  ]);

  useEffect(() => {
    const getSettings = async () => {
      try {
        const appSettings = await getAppSettings();
        if (appSettings) {
          setAppSettings(appSettings);
          setIsLoadingSettings(false);
        }

        const states = await getStates();
        if (states) {
          setEnabledStates(states.filter((state) => state.enabled));
        }
      } catch (err) {
        console.error(`Error: ${JSON.stringify(err)}`);
      } finally {
        setIsLoadingSettings(false);
      }
    };
    getSettings();
  }, []);

  useEffect(() => {
    onAuthStateChangedHook(async (authUser) => {
      try {
        setIsWaitingForAuth(true);

        if (!authUser) {
          setSignedInUser(null);

          return;
        }

        const {
          uid,
          email: authEmail,
          phoneNumber: authPhoneNumber,
        } = authUser;

        if (!authPhoneNumber) {
          console.error("Authenticated user has no phone number");
          setToastData([
            ...toastData,
            {
              toastType: ToastType.info,
              header: "Something went wrong",
            },
          ]);

          return;
        }

        setSignedInUser({
          uid,
          email: authEmail || contextProposal?.prospect?.email || "",
          phoneNumber:
            authPhoneNumber || contextProposal?.prospect?.phoneNumber || "",
          firstName: contextProposal?.prospect?.firstName || "",
          lastName: contextProposal?.prospect?.lastName || "",
        });
      } catch (err) {
        console.error("Error logging in", { err });
        setSignedInUser(null);
        setToastData([
          ...toastData,
          {
            toastType: ToastType.info,
            header: "Error logging in",
            body: "Please try refreshing the page or contact us for help",
          },
        ]);
      } finally {
        setIsWaitingForAuth(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!signedInUser) {
      history.push(`/making-sure-its-you${search}`);
    }
  }, [signedInUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsModalOpen(false);
    setModalContent(null);
  }, [pathname]);

  useEffect(() => {
    const getDownpayment = async () => {
      if (!contextProposalId || !contextProposal) return;

      setIsLoadingProposal(true);
      const { prospect } = contextProposal;

      const {
        email = "",
        firstName = "",
        lastName = "",
        phoneNumber = "",
        location = {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      } = prospect || {};

      const {
        result: {
          downPaymentMinimum: downPaymentMinimumPercent,
          plansAndSavings,
        },
        status,
      } = await getDownPaymentCalculations({
        proposalId: contextProposalId,
        preApplyDetails: {
          email,
          phoneNumber,
          firstName,
          lastName,
          location: {
            address: location?.address || "",
            city: location?.city || "",
            state: location?.state || "",
            zip: location?.zip || "",
          },
        },
      });
      if (status === 200) {
        if (!isNaN(downPaymentMinimumPercent)) {
          setDownPaymentMinimumPercent(downPaymentMinimumPercent);
        }
        if (plansAndSavings) setDownpaymentCalculations(plansAndSavings);
      } else {
        console.error("Error calculating down payments");
        setToastData([
          ...toastData,
          {
            toastType: ToastType.info,
            header: "Error calculating down payments",
            body: "Please try refreshing the page or contact us for help",
          },
        ]);
      }
      setIsLoadingProposal(false);
    };
    getDownpayment();
  }, [contextProposalId]);

  return isWaitingForAuth ||
    isAppLoading ||
    isLoadingProposal ||
    isLoadingSettings ? (
    <Loading />
  ) : (
    <div>
      <Switch>
        <div className={`mb-10 mb-md-0`}>
          <Header>
            {toastData.length > 0 &&
              toastData.map(({ header, body, toastType, timeout }, idx) => (
                <Toast
                  key={`toast-${idx}`}
                  header={header}
                  body={body}
                  toastType={toastType}
                  timeout={timeout}
                />
              ))}
          </Header>
          <Route path="/step-:stepId">
            <Stepper />
          </Route>
          <Route path="/no-plans-found">
            <NoPlansFound />
          </Route>
          <Route path="/go-solar">
            <StartSplash />
          </Route>
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/success-sunlight">
            <SuccessSunlight />
          </Route>
          <Route path="/making-sure-its-you">
            <OtpScreen />
          </Route>
          <Route path="/contact-installer">
            <ContactInstaller />
          </Route>
          <Route path="/start-fresh">
            <StartFresh />
          </Route>
          <Route path="/something-went-wrong">
            <PageNotFound />
          </Route>
          <Route path="/step-:stepId">
            <section id="section-page" className="mt-2 mt-md-0">
              <Steps />
            </section>
          </Route>
          <Route path="/" exact>
            <Redirect to={`/go-solar${search}`} />
          </Route>
        </div>
      </Switch>
      <Footer />
      <Modal />
    </div>
  );
};

const App: FunctionComponent<{}> = () => {
  return (
    <RecoilRoot>
      <Router>
        <WrappedApp />
      </Router>
    </RecoilRoot>
  );
};

export default App;
