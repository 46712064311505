/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FunctionComponent,
  ReactChild,
  useCallback,
  useState,
} from "react";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useRecoilValue } from "recoil";

import mapPin from "../assets/images/map-pin.svg";

function addCommasAndAnd(list: string[]) {
  if (list.length < 3) {
    return list.join(" and ");
  }

  return `${list.slice(0, -1).join(", ")}, and ${list[list.length - 1]}`;
}

const containerStyle = {
  width: "100%",
  height: "300px",
  borderRadius: "10px",
  marginTop: "16px",
};

const CENTER_USA = {
  lat: 39.8097343,
  lng: -98.5556199,
};

const USA_BOUNDS = {
  north: 49.3457868,
  south: 24.7433195,
  west: -124.7844079,
  east: -66.9513812,
};

const mapOptions: google.maps.MapOptions = {
  center: CENTER_USA,
  restriction: {
    latLngBounds: USA_BOUNDS,
    strictBounds: false,
  },
  zoom: 4,
};

const getAddressObject = (address_components: any) => {
  const ShouldBeComponent: any = {
    home: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    region: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    country: ["country"],
  };

  const address: any = {
    home: "",
    postal_code: "",
    street: "",
    region: "",
    city: "",
    country: "",
  };
  address_components.forEach((component: any) => {
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country" || shouldBe === "region") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  return address;
};

interface MapComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  input: ReactChild;
  onPlaceSelected: (selectedPlace: unknown) => void;
  coordinates?: { lat: number; lng: number };
}

const MapComponent: FunctionComponent<MapComponentProps> = ({
  input,
  onPlaceSelected,
  coordinates,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ["places"],
  });

  const [currentMap, setCurrentMap] = useState<google.maps.Map | null>(null);
  const [marker, setMarker] = useState<any>();
  const [autoComplete, setAutoComplete] = useState<any>(null);

  const onLoad = useCallback(function callback(map) {
    if (coordinates) {
      const bounds = new window.google.maps.LatLngBounds({
        lat: coordinates.lat,
        lng: coordinates.lng,
      });

      map?.setCenter(bounds.getCenter());
      map?.setZoom(16);
    }
    setCurrentMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setCurrentMap(null);
  }, []);

  const onAutoCompleteLoad = React.useCallback(function callback(autocomplete) {
    setAutoComplete(autocomplete);
  }, []);

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      setMarker(null);

      const autoCompletePlace = autoComplete.getPlace();

      const coordinates = {
        lat: autoCompletePlace.geometry.location.lat(),
        lng: autoCompletePlace.geometry.location.lng(),
      };

      const bounds = new window.google.maps.LatLngBounds(coordinates);

      const addressObject = getAddressObject(
        autoCompletePlace.address_components
      );

      currentMap?.fitBounds(bounds);
      currentMap?.setCenter(bounds.getCenter());
      currentMap?.setZoom(20);

      setMarker({ position: coordinates });
      onPlaceSelected({
        autoCompletePlace,
        addressObject,
        coordinates,
      });
    } else {
      console.warn("Autocomplete is not loaded yet!");
    }
  };

  return isLoaded ? (
    <>
      <Autocomplete
        restrictions={{ country: "USA" }}
        onLoad={onAutoCompleteLoad}
        onPlaceChanged={onPlaceChanged}
      >
        {input}
      </Autocomplete>
      {/* <div className="mt-2">
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={mapOptions}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {marker && (
            <Marker
              {...marker}
              icon={mapPin}
              animation={google.maps.Animation.DROP}
            />
          )}
        </GoogleMap>
      </div> */}
    </>
  ) : (
    <></>
  );
};

export default React.memo(MapComponent);
