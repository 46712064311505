import dayjs from "dayjs";
import CustomParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(CustomParseFormat);

export const isValidEmail = (email: string | undefined): boolean => {
  if (!email) return false;

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const isValidPhoneNumber = (
  phoneNumber: string | undefined
): boolean => {
  if (!phoneNumber) return false;

  const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return re.test(phoneNumber);
};

export const isValidDate = (dateString: string) => {
  const isValidDate = dayjs(dateString, "MMDDYYYY", true);
  return isValidDate.isValid();
};

export const isOlderThan = (dateString: string, age: number) => {
  return dayjs().diff(dayjs(dateString, "MMDDYYYY"), "y") > age;
};

export const getNumberAsLocalString = (num: number) => {
  return Math.round(num).toLocaleString("en", {
    useGrouping: true,
  });
};
