import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { useRecoilState } from "recoil";
import { toastDataAtom } from "../state/atoms/ui";

import bellIcon from "../assets/images/bell.svg";
import checkCircleIcon from "../assets/images/check-circle.svg";
import infoCircleIcon from "../assets/images/info-circle.svg";
import messageBubbleIcon from "../assets/images/message-bubble.svg";
import stopWatchIcon from "../assets/images/stop-watch.svg";
import thumbsUpIcon from "../assets/images/thumbs-up.svg";
import useWindowDimensions from "../hooks";
import { WindowBreakPoints } from "../types/ui";

export enum ToastType {
  info = "info",
  alert = "alert",
  contact = "contact",
  check = "check",
  timer = "timer",
  success = "success",
}

interface ToastProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: ReactNode;
  body?: ReactNode;
  toastType?: ToastType;
  timeout?: number;
}

export const Toast: FunctionComponent<ToastProps> = ({
  header,
  body = "",
  toastType = "info",
  timeout = 3000,
}) => {
  const { width } = useWindowDimensions();

  const [toastData, setToastData] = useRecoilState(toastDataAtom);

  const getIcon = () => {
    switch (toastType) {
      case ToastType.info:
        return infoCircleIcon;
      case ToastType.alert:
        return bellIcon;
      case ToastType.contact:
        return messageBubbleIcon;
      case ToastType.timer:
        return stopWatchIcon;
      case ToastType.check:
        return checkCircleIcon;
      case ToastType.success:
        return thumbsUpIcon;
      default:
        return infoCircleIcon;
    }
  };

  const closeToast = () => {
    setToastData(toastData.slice(0, -1));
  };

  useEffect(() => {
    const timeoutHandle = setTimeout(() => closeToast(), timeout);

    return () => clearTimeout(timeoutHandle);
  }, [timeout]);

  return header || body ? (
    <div
      className="toast show position-absolute mb-1 animate__animated animate__fadeInRight"
      role="button"
      onClick={closeToast}
      style={{
        top: "0px",
        right: width < WindowBreakPoints.lg ? "0.5rem" : "-0.75rem",
        backgroundColor: "#ffffff",
        borderRadius: "0.5rem",
        border: "1px solid #dfdfdf",
        padding: "0.25rem",
        boxShadow: "unset",
        width: "unset",
      }}
    >
      <div className={`toast-header px-1 border-0 pb-1`}>
        <img src={getIcon()} alt="" />
        <div
          className="m-0 ml-1 mt-025 align-baseline d-inline"
          style={{ color: "#484848" }}
        >
          {header}
        </div>
      </div>
      {body && (
        <div className="toast-body p-1 pt-0 bg-white">
          <small>{body}</small>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
