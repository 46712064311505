import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import loader from "../../assets/images/loader-white.gif";
import {
  attachProposalToProspect,
  getDownPaymentCalculations,
  signUserOut,
} from "../../services/firebase";
import { PRE_QUALIFICATION_NEXT_STEPS } from "../../types/application";
import { signedInUserAtom } from "../../state/atoms/auth";
import {
  addressInputAtom,
  addressLngAtom,
  addressLatAtom,
  customerDetailsFirstNameAtom,
  customerDetailsLastNameAtom,
  customerDetailsEmailAtom,
  customerDetailsAddressAtom,
  customerDetailsCityAtom,
  customerDetailsStateAtom,
  customerDetailsZipAtom,
  customerDetailsPhoneNumberAtom,
} from "../../state/atoms/form";
import {
  contextProposalIdAtom,
  contextProposalAtom,
  downpaymentCalculationsAtom,
  downPaymentMinimumPercentAtom,
  isLeasePlanHiddenAtom,
  canQualifyForLoan,
  isArrayNotFound,
} from "../../state/atoms/global";
import {
  canSubmitStep2Selector,
  isCustomerDetailsEmailChangedSelector,
  isCustomerDetailsEmailValidSelector,
  isCustomerDetailsPhoneNumberChangedSelector,
  isCustomerDetailsPhoneNumberValidSelector,
} from "../../state/selectors/form";
import { isAppLoadingAtom, isInDemoMode } from "../../state/atoms/ui";
import Map from "../Map";
import NoPlansFound from "../../components/steps/NoPlansFound";

const Step1: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const [arrayNotFound, setArrayNotFound] = useRecoilState(isArrayNotFound);
  const setIsInDemoMode = useSetRecoilState(isInDemoMode);
  const setCanQualifyForLoan = useSetRecoilState(canQualifyForLoan);
  const setIsAppLoading = useSetRecoilState(isAppLoadingAtom);
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const contextProposalId = useRecoilValue(contextProposalIdAtom);
  const [contextProposal, setContextProposal] =
    useRecoilState(contextProposalAtom);

  const [customerDetailsFirstName, setCustomerDetailsFirstName] =
    useRecoilState(customerDetailsFirstNameAtom);
  const [customerDetailsLastName, setCustomerDetailsLastName] = useRecoilState(
    customerDetailsLastNameAtom
  );
  const [customerDetailsEmail, setCustomerDetailsEmail] = useRecoilState(
    customerDetailsEmailAtom
  );
  const isDemo = !!customerDetailsEmail?.includes("+demo@rhino-eco.com");
  setIsInDemoMode(isDemo);
  const [customerDetailsPhoneNumber, setCustomerDetailsPhoneNumber] =
    useRecoilState(customerDetailsPhoneNumberAtom);
  const [customerDetailsAddress, setCustomerDetailsAddress] = useRecoilState(
    customerDetailsAddressAtom
  );
  const [customerDetailsCity, setCustomerDetailsCity] = useRecoilState(
    customerDetailsCityAtom
  );
  const [customerDetailsState, setCustomerDetailsState] = useRecoilState(
    customerDetailsStateAtom
  );
  const [customerDetailsZip, setCustomerDetailsZip] = useRecoilState(
    customerDetailsZipAtom
  );
  const isCustomerDetailsEmailValid = useRecoilValue(
    isCustomerDetailsEmailValidSelector
  );
  const isCustomerDetailsEmailChanged = useRecoilValue(
    isCustomerDetailsEmailChangedSelector
  );
  const isCustomerDetailsPhoneNumberValid = useRecoilValue(
    isCustomerDetailsPhoneNumberValidSelector
  );
  const isCustomerDetailsPhoneNumberChanged = useRecoilValue(
    isCustomerDetailsPhoneNumberChangedSelector
  );

  const [addressInput, setAddressInput] = useRecoilState(addressInputAtom);
  const [addressLng, setAddressLng] = useRecoilState(addressLngAtom);
  const [addressLat, setAddressLat] = useRecoilState(addressLatAtom);
  const [isAddressInputValid, setIsAddressInputValid] = useState(true);

  const setIsLeasePlanHidden = useSetRecoilState(isLeasePlanHiddenAtom);
  const setDownpaymentMinimumPercent = useSetRecoilState(
    downPaymentMinimumPercentAtom
  );
  const setDownpaymentCalculations = useSetRecoilState(
    downpaymentCalculationsAtom
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const canSubmitStep = useRecoilValue(canSubmitStep2Selector);

  const prequalify = useCallback(async (): Promise<void> => {
    try {
      setIsSubmitting(true);
      const {
        result: { nextStep, downPaymentMinimum, plansAndSavings },
        status,
      } = await getDownPaymentCalculations({
        proposalId: contextProposalId || "",
        preApplyDetails: {
          email: customerDetailsEmail || "",
          phoneNumber: customerDetailsPhoneNumber || "",
          firstName: customerDetailsFirstName || "",
          lastName: customerDetailsLastName || "",
          location: {
            address: customerDetailsAddress || "",
            city: customerDetailsCity || "",
            state: customerDetailsState || "",
            zip: customerDetailsZip || "",
          },
        },
      });
      if (status === 200) {
        const calculatedDownpaymentMinimum = !isNaN(downPaymentMinimum)
          ? downPaymentMinimum
          : 50;
        setDownpaymentMinimumPercent(calculatedDownpaymentMinimum);
        if (plansAndSavings) setDownpaymentCalculations(plansAndSavings);

        if (isDemo) {
          return history.push(`/step-2${search}`);
        }

        if (nextStep) {
          if (
            nextStep === PRE_QUALIFICATION_NEXT_STEPS.NOT_FOUND &&
            !arrayNotFound
          ) {
            setArrayNotFound(true);
            setIsSubmitting(false);
            return;
          }
          if (
            [
              PRE_QUALIFICATION_NEXT_STEPS.APPLY,
              PRE_QUALIFICATION_NEXT_STEPS.NOT_VERIFIED,
              PRE_QUALIFICATION_NEXT_STEPS.NOT_QUALIFIED,
              PRE_QUALIFICATION_NEXT_STEPS.NOT_FOUND,
            ].includes(nextStep)
          ) {
            if (nextStep === PRE_QUALIFICATION_NEXT_STEPS.NOT_QUALIFIED) {
              setIsLeasePlanHidden(true);
              setCanQualifyForLoan(false);
            }
            if (
              [
                PRE_QUALIFICATION_NEXT_STEPS.NOT_VERIFIED,
                PRE_QUALIFICATION_NEXT_STEPS.NOT_FOUND,
              ].includes(nextStep)
            ) {
              setIsLeasePlanHidden(true);
            }
            if (!signedInUser) {
              return history.push(`/making-sure-its-you${search}`);
            }
            return history.push(`/step-2${search}`);
          } else if (
            nextStep === PRE_QUALIFICATION_NEXT_STEPS.CONTACT_INSTALLER
          ) {
            return history.push(`/contact-installer${search}`);
          }
        } else {
          return history.push(`/step-2${search}`);
        }
      } else {
        setIsSubmitting(false);
        throw new Error("Down payment calculations failed");
      }
    } catch (error) {
      console.error("Error prequalifying");
    } finally {
      setIsSubmitting(false);
    }
  }, [
    !!signedInUser,
    customerDetailsEmail,
    customerDetailsPhoneNumber,
    customerDetailsFirstName,
    customerDetailsLastName,
    customerDetailsAddress,
    customerDetailsCity,
    customerDetailsState,
    customerDetailsZip,
    arrayNotFound,
  ]);

  const attachProposal = useCallback(async (): Promise<void> => {
    try {
      setIsSubmitting(true);
      setArrayNotFound(false);
      const { status, result } = await attachProposalToProspect({
        proposalId: contextProposalId || "",
        preApplyDetails: {
          email: customerDetailsEmail || "",
          phoneNumber: customerDetailsPhoneNumber || "",
          firstName: customerDetailsFirstName || "",
          lastName: customerDetailsLastName || "",
          location: {
            address: customerDetailsAddress || "",
            city: customerDetailsCity || "",
            state: customerDetailsState || "",
            zip: customerDetailsZip || "",
          },
        },
      });
      if (status !== 200 || !result || !result.proposal) {
        throw new Error("Error attaching proposal to prospect");
      }
      if (customerDetailsPhoneNumber !== phoneNumber) {
        setIsAppLoading(true);
        await signUserOut(setSignedInUser);
        window.location.reload();
        return;
      } else {
        const { proposal } = result;
        setContextProposal(proposal);
        setIsAppLoading(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error attaching proposal to prospect");
      setIsAppLoading(false);
      setIsSubmitting(false);
    }
  }, [
    customerDetailsEmail,
    customerDetailsPhoneNumber,
    customerDetailsFirstName,
    customerDetailsLastName,
    customerDetailsAddress,
    customerDetailsCity,
    customerDetailsState,
    customerDetailsZip,
  ]);

  const { prospect } = contextProposal || {};

  const { firstName, lastName, email, phoneNumber, location } = prospect || {};
  const { address, city, state, zip } = location || {};
  const defaultAddressInput = `${address} ${city}, ${state} ${zip}`;

  const isUserDetailsEqual =
    customerDetailsFirstName === firstName &&
    customerDetailsLastName === lastName &&
    customerDetailsEmail === email &&
    customerDetailsPhoneNumber === phoneNumber &&
    addressInput === defaultAddressInput;

  useEffect(() => {
    setAddressInput(defaultAddressInput);
  }, [defaultAddressInput]);

  const ctaText = () => {
    if (isSubmitting) {
      return <img height="22px" src={loader} alt="Submitting..." />;
    }

    if (arrayNotFound) {
      return "Continue Anyway";
    }

    return "Prequalify";
  };

  return (
    <div className="px-3 px-lg-0">
      <h1>Let&apos;s get to know you</h1>
      <h5 className="d-block d-md-none">Let&apos;s get to know you</h5>
      <div className="subtitle">Please review and confirm your details</div>
      <div className="max-w-800px m-auto">
        <div className="row mt-2 form-group">
          <div className="px-05 col-12 col-md-6 form-floating">
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First Name"
              value={customerDetailsFirstName || ""}
              onChange={(e) => setCustomerDetailsFirstName(e.target.value)}
            />
            <label htmlFor="firstName">
              First Name<span className="text-primary ml-025">*</span>
            </label>
          </div>
          <div className="px-05 col-12 col-md-6 mt-2 mt-md-0 form-floating">
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last Name"
              value={customerDetailsLastName || ""}
              onChange={(e) => setCustomerDetailsLastName(e.target.value)}
            />
            <label htmlFor="lastName">
              Last Name<span className="text-primary ml-025">*</span>
            </label>
          </div>
        </div>
        <div className="row mt-2 form-group">
          <div className="px-05 col-12 col-md-6 form-floating">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              value={customerDetailsEmail || ""}
              onChange={(e) => setCustomerDetailsEmail(e.target.value)}
            />
            <label htmlFor="email">
              Email<span className="text-primary ml-025">*</span>
            </label>
            {customerDetailsEmail &&
              (!isCustomerDetailsEmailValid ||
                isCustomerDetailsEmailChanged) && (
                <div className="col-12 mt-1 text-start">
                  <div className="px-1 text-primary text-small">
                    {!isCustomerDetailsEmailValid
                      ? "Invalid email"
                      : "This address will be used to contact you from now on."}
                  </div>
                </div>
              )}
          </div>
          <div className="px-05 col-12 col-md-6 mt-2 mt-md-0 form-floating">
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              placeholder="Phone number"
              value={customerDetailsPhoneNumber || ""}
              onChange={(e) => setCustomerDetailsPhoneNumber(e.target.value)}
            />
            <label htmlFor="phoneNumber">
              Phone number<span className="text-primary ml-025">*</span>
            </label>
            {customerDetailsPhoneNumber &&
              (!isCustomerDetailsPhoneNumberValid ||
                isCustomerDetailsPhoneNumberChanged) && (
                <div className="col-12 mt-1 text-start">
                  <div className="px-1 text-primary text-small">
                    {!isCustomerDetailsPhoneNumberValid
                      ? "Invalid phone number"
                      : "This number will be used for logging in. Have it ready for verification."}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="row mt-2 form-group">
          <div className="col-12 px-05 form-floating">
            <Map
              coordinates={
                addressLat && addressLng
                  ? {
                      lat: addressLat,
                      lng: addressLng,
                    }
                  : undefined
              }
              input={
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    id="address"
                    value={addressInput || ""}
                    onChange={(event) => {
                      setAddressInput(event.target.value);
                      setIsAddressInputValid(false);
                    }}
                  />
                  <label htmlFor="address">
                    Address<span className="text-primary ml-025">*</span>
                  </label>
                </div>
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onPlaceSelected={(location: any) => {
                if (!location) {
                  setAddressInput(null);
                  setCustomerDetailsCity(null);
                  setCustomerDetailsZip(null);
                  setCustomerDetailsState(null);
                  setCustomerDetailsAddress(null);
                  setAddressLat(null);
                  setAddressLng(null);
                  return;
                }

                const { autoCompletePlace, addressObject, coordinates } =
                  location;

                setAddressInput(autoCompletePlace.formatted_address);
                setCustomerDetailsCity(addressObject.city);
                setCustomerDetailsZip(addressObject.postal_code);
                setCustomerDetailsState(addressObject.region);
                setCustomerDetailsAddress(
                  `${addressObject.home} ${addressObject.street}`
                );
                setAddressLat(coordinates.lat);
                setAddressLng(coordinates.lng);
                setIsAddressInputValid(true);
              }}
            />
          </div>
        </div>
        {arrayNotFound && (
          <div
            style={{
              backgroundColor: "#F6D4EB",
              borderRadius: "4px",
              fontSize: "12px",
              padding: "7px",
              margin: "17px -9px -7px",
            }}
          >
            <span>Something looks odd, please check your details again.</span>
          </div>
        )}
        <div className="row mt-3 form-group">
          <div className="col-12 px-05">
            {isUserDetailsEqual ? (
              <button
                type="submit"
                className="btn btn-primary w-100 font-weight-600"
                disabled={!canSubmitStep || isSubmitting}
                onClick={prequalify}
              >
                {ctaText()}
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary w-100 font-weight-600"
                disabled={
                  !canSubmitStep || isSubmitting || !isAddressInputValid
                }
                onClick={attachProposal}
              >
                {isSubmitting ? (
                  <img height="22px" src={loader} alt="Submitting..." />
                ) : (
                  "Save details"
                )}
              </button>
            )}
          </div>
        </div>
        <div className="row mt-2 form-group text-start">
          <div className="form-check text-muted text-medium">
            You agree that by clicking on the &quot;Prequalify&quot; button
            below, you agree to Rhino&apos;s{" "}
            <a
              href="https://rhino-eco.com/terms-conditions"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            {" and "}
            <a
              href="https://rhino-eco.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            , and you are providing &quot;written instructions&quot; to Rhino
            Eco, authorizing Rhino Eco to obtain your credit profile from any
            consumer reporting agency which can be used to evaluate your
            financial history for the purpose of providing you with targeted
            offers based on your credit profile.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
