import React, { FunctionComponent, useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";

import closeIcon from "../assets/images/close-icon.svg";

import { isModalOpenAtom, modalContentAtom } from "../state/atoms/ui";

export const Modal: FunctionComponent<{}> = () => {
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);
  const [modalContent, setModalContent] = useRecoilState(modalContentAtom);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsModalOpen(false);
    }
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    const htmlElement = document.getElementsByTagName("html")[0];
    if (isModalOpen) {
      htmlElement.style.overflowY = "hidden";
    }

    return () => {
      htmlElement.style.overflowY = "unset";
    };
  }, [isModalOpen]);

  if (!modalContent) return <></>;

  const { header, body, footer, width } = modalContent;

  return isModalOpen ? (
    <div className="modal-backdrop-cover">
      <div role="button" className="modal d-block show" onClick={closeModal}>
        <div
          role="button"
          className={`cursor-arrow modal-dialog modal-lg modal-dialog-centered h-auto m-auto`}
          style={{ width: width || "auto", maxWidth: "100%" }}
          onClick={(evt) => evt.stopPropagation()}
        >
          <div className="modal-content position-relative">
            <div className="modal-header p-0 pl-2 border-0 d-block">
              <div className="float-left">{header || <></>}</div>
              <div
                role="button"
                className="float-right py-1 px-2 modal-header-button"
                onClick={closeModal}
              >
                <img src={closeIcon} alt="close" />
              </div>
            </div>
            <div className="modal-body pt-0 px-0">{body}</div>
            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
