import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import greenGlove from "../../assets/images/plans/green-globe.svg";
import { getSalesRepById } from "../../services/firebase";
import {
  contextProposalAtom,
  contextSaleRepAtom,
} from "../../state/atoms/global";
import { Loading } from "../Loading";
import { useHistory, useLocation } from "react-router-dom";

const NoPlansFound = () => {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const proposalId =
    queryParams.get("proposalId") || queryParams.get("proposalid");
  const contextProposal = useRecoilValue(contextProposalAtom);
  const [salesRep, setSalesRep] = useRecoilState(contextSaleRepAtom);
  const [isLoading, setIsLoading] = useState(!salesRep);

  const handleGoBack = () => {
    history.push(`/step-1?proposalId=${proposalId}`);
  };

  useEffect(() => {
    const getSalesRep = async (id: string) => {
      try {
        setIsLoading(true);
        const saleRep = await getSalesRepById(id);
        setSalesRep(saleRep);
        setIsLoading(false);
      } catch (err) {
        handleGoBack();
      }
    };

    const salesRepId = contextProposal?.salesRepId;

    if (!salesRep && salesRepId) {
      getSalesRep(salesRepId);
    }
  }, [contextProposal?.salesRepId]);

  return (
    <div
      style={{
        background: "var(--brand-background-color)",
        minHeight: "calc(100vh - 74px)",
        paddingTop: 5,
        position: "relative",
      }}
    >
      {(isLoading && <Loading isContained />) || (
        <>
          <div className="container-lg d-flex my-4 p-0">
            <div className="d-none d-lg-block w-100px text-start">
              <button
                className={`stepper-element btn btn-outline-secondary py-025`}
                onClick={handleGoBack}
              >
                <span className="text-uppercase">
                  <span className="mr-05" style={{ marginTop: "-2px" }}>
                    <svg
                      width="7"
                      height="8"
                      viewBox="0 0 7 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.603895 3.92009C0.335497 3.76513 0.335498 3.37773 0.603896 3.22277L6.03896 0.0848324C6.30736 -0.0701268 6.64286 0.123573 6.64286 0.433492L6.64286 6.70937C6.64286 7.01928 6.30736 7.21298 6.03896 7.05802L0.603895 3.92009Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  Back
                </span>
              </button>
            </div>
          </div>
          <div
            style={{
              margin: "0 auto",
              background: "white",
              padding: "27px 43px",
              maxWidth: 356,
              border: "1px solid #DFDFDF",
              borderRadius: 5,
            }}
            className="text-center"
          >
            <span
              style={{
                background: "#E9F8E8",
                borderRadius: 20,
                position: "relative",
                height: 41,
                width: 41,
                display: "inline-block",
                border: "1px solid #000",
                boxShadow: "gray 0px 6px 5px -3px",
              }}
            >
              <img
                src={greenGlove}
                alt="greenGlove"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -66%)",
                }}
              />
            </span>
            <h2
              style={{
                paddingLeft: 18,
                paddingRight: 18,
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: 14,
                lineHeight: "20px",
                color: "#484848",
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              We couldn’t find any plans that fit here...
            </h2>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: 14,
                lineHeight: "22px",
                color: "#484848",
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              If you’d like to edit your details to try again, or have someone
              else whose listed on the property pre-qualify, click here.
            </p>
            <button
              className="btn btn-primary"
              style={{
                padding: "10px 42px",
              }}
              onClick={handleGoBack}
            >
              Start over
            </button>
            <div>
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  lineHeight: "22px",
                  color: "#484848",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                or
              </span>
            </div>
            <div>
              <a
                href={`mailto:${salesRep?.email}?subject=Payments and financing options`}
                style={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  lineHeight: "15px",
                  color: "#2A4E96",
                }}
              >
                Contact installer
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NoPlansFound;
