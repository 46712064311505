import React, { FunctionComponent } from "react";
import facebookIcon from "../assets/images/facebook.svg";
import instagramIcon from "../assets/images/instagram.svg";
import linkedInIcon from "../assets/images/linkedin.svg";
import mediumIcon from "../assets/images/medium.svg";
import Lottie from "lottie-react";

import confirmationAnimation from "../assets/animations/confirmation.json";

const SocialLinks: FunctionComponent = () => {
  return (
    <div className="row mt-2">
      <div className="col-12">
        <a
          href="https://www.facebook.com/Rhino-Eco-109303365029213"
          title="Facebook"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={facebookIcon}
            alt="Facebook"
            title="Facebook"
            width={32}
            style={{
              outline: "none",
              textDecoration: "none",
              clear: "both",
              display: "block!important",
              border: "none",
              height: "auto",
              float: "none",
              maxWidth: "32px!important",
            }}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/rhino-eco/"
          className="ml-1"
          title="LinkedIn"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={linkedInIcon}
            alt="LinkedIn"
            title="LinkedIn"
            width={32}
            style={{
              outline: "none",
              textDecoration: "none",
              clear: "both",
              display: "block!important",
              border: "none",
              height: "auto",
              float: "none",
              maxWidth: "32px!important",
            }}
          />
        </a>
        <a
          href="https://www.instagram.com/rhino_eco/?hl=en"
          className="ml-1"
          title="Instagram"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={instagramIcon}
            alt="Instagram"
            title="Instagram"
            width={32}
            style={{
              outline: "none",
              textDecoration: "none",
              clear: "both",
              display: "block!important",
              border: "none",
              height: "auto",
              float: "none",
              maxWidth: "32px!important",
            }}
          />
        </a>
        <a
          href="https://medium.com/rhino-eco?source=post_page-----e08e84732f68-----------------------------------"
          className="ml-1"
          title="Medium"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={mediumIcon}
            alt="Medium"
            title="Medium"
            width={32}
            style={{
              outline: "none",
              textDecoration: "none",
              clear: "both",
              display: "block!important",
              border: "none",
              height: "auto",
              float: "none",
              maxWidth: "32px!important",
            }}
          />
        </a>
      </div>
    </div>
  );
};

const Success: FunctionComponent<{}> = () => {
  return (
    <div className="text-center rhino_animate__animated rhino_animate__fadeIn">
      <h1 className="m-0 font-weight-600">Got it!</h1>
      <h6 className="mt-1 mb-0">Your installer will get back to you ASAP</h6>
      <div className={`m-auto w-100 w-md-50 max-w-400px`}>
        <Lottie
          animationData={confirmationAnimation}
          loop={true}
          height={100}
        />
      </div>
      <h6>Until then, feel free to follow us on</h6>
      <SocialLinks />
    </div>
  );
};

export default Success;
