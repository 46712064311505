import React, { FunctionComponent, useEffect } from "react";
import sunlightFinancialIcon from "../assets/images/pre-checkout/sunlight-financial.svg";

import splashAnimation from "../assets/animations/splash.json";
import { useLottie } from "lottie-react";
import { getLoanCreditUrl } from "../services/firebase";
import { contextProposalIdAtom } from "../state/atoms/global";
import { useRecoilValue } from "recoil";

const AnimationComponent = () => {
  const contextProposalId = useRecoilValue(contextProposalIdAtom);

  const lottieOptions = {
    animationData: splashAnimation,
    loop: true,
    autoplay: true,
    height: 100,
  };

  const Lottie = useLottie(lottieOptions);

  useEffect(() => {
    Lottie.setSpeed(1.5);
  }, []);

  useEffect(() => {
    const fetchLoanCreditUrl = async () => {
      try {
        if (contextProposalId) {
          const { result, status } = await getLoanCreditUrl({
            proposalId: contextProposalId,
          });
          if (status === 200 && result?.creditUrl) {
            window.location.href = result.creditUrl;
            return;
          } else {
            console.error("Error applying for loan");
          }
        }
      } catch (error) {
        console.error("Error applying for loan", error);
      }
    };

    fetchLoanCreditUrl();
  }, []);

  return Lottie.View;
};
const SuccessSunlight: FunctionComponent<{}> = () => {
  return (
    <div className="text-center rhino_animate__animated rhino_animate__fadeIn">
      <div className="text-center mb-4">
        <h1 className="m-0 font-weight-600 mb-3">
          You&apos;re about to go solar
        </h1>
        <span className="mb-1 text-black text-bold">
          with
          <img
            className="ml-1"
            src={sunlightFinancialIcon}
            alt="sunlight-financial"
          />
        </span>
      </div>

      <div
        className={`m-auto w-100 w-md-50 max-w-400px`}
        style={{
          marginTop: "-20rem",
          marginBottom: "-20rem",
        }}
      >
        <AnimationComponent />
      </div>
    </div>
  );
};

export default SuccessSunlight;
