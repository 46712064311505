import React, { useState, useEffect } from "react";
import { Tag, TagType } from "../Tag";
import {
  contextITCRateAtom,
  contextProposalAtom,
  downpaymentCalculationsAtom,
} from "../../state/atoms/global";
import { useRecoilValue } from "recoil";
import CountUp from "react-countup";

export const MockStripeCheckout = () => {
  const ITCRate = useRecoilValue(contextITCRateAtom);
  const contextProposal = useRecoilValue(contextProposalAtom);
  const downpaymentCalculations = useRecoilValue(downpaymentCalculationsAtom);
  const [systemCost, setSystemCost] = useState(0);
  const [lifetimeSavings, setLifetimeSavings] = useState(0);
  const [federalIncentive, setFederalIncentive] = useState(0);
  const [systemSize, setSystemSize] = useState(0);
  const [installerName, setInstallerName] = useState("");

  useEffect(() => {
    setSystemCost(downpaymentCalculations[0].calculatedPlans.payNow);
    setLifetimeSavings(
      downpaymentCalculations[0].savingDetails.payNow.lifeTimeSavings
    );

    setFederalIncentive(
      downpaymentCalculations[0].calculatedPlans.payNow * ITCRate
    );

    if (
      contextProposal?.deal?.systemLocation &&
      contextProposal?.deal?.systemDetails
    ) {
      const { systemDetails } = contextProposal.deal;
      setInstallerName(contextProposal.installer.name || "");
      setSystemSize(systemDetails.systemSize);
    }
  }, [contextProposal, downpaymentCalculations]);

  return (
    <form>
      <div className="mx-auto px-3 px-md-0 mt-8 max-w-1100px">
        <div className="row">
          <div className="col-12 col-lg-6 text-start">
            <h5 className="mb-2">
              Let&apos;s connect.
              <br />
              Your account won’t be charged.
            </h5>
            <div className="text-medium text-muted mb-2">
              You will only be charged once you sign the contract, according to
              agreed milestones.
            </div>
            <div className="card rounded">
              <div className="card-body pb-2 pt-3 px-2">
                <div className="d-flex px-2">
                  <h5 className="text-bold mb-0">Payment Summary</h5>
                  <div className="ms-auto">
                    <Tag tagType={TagType.buy} width="unset">
                      MILESTONES PAYMENTS
                    </Tag>
                  </div>
                </div>
                <div className="d-flex mt-2 px-2">
                  <div className="mr-4">
                    <div className="text-medium text-muted mb-05">
                      System Size
                    </div>
                    <div>
                      <span className="text-medium">
                        <h5 className="d-inline">{systemSize}</h5>
                        kW
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-medium text-muted mb-05">
                      Installer
                    </div>
                    <div>
                      <span className="text-medium">
                        <h5 className="d-inline">{installerName}</h5>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-2 mx-2 py-1 border-bottom">
                  <div>System Cost</div>
                  <div className="ms-auto">
                    <span className="text-medium">
                      $
                      <h5 className="d-inline">
                        <CountUp
                          end={systemCost}
                          duration={1}
                          separator=","
                          decimal="."
                        />
                      </h5>
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-2 mx-2 py-1">
                  <div>Federal Incentive</div>
                  <div className="ms-auto">
                    <span className="text-medium">
                      -$
                      <h5 className="d-inline">
                        <CountUp
                          end={federalIncentive}
                          duration={1}
                          separator=","
                          decimal="."
                        />
                      </h5>
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-2 px-2 pt-2 pb-1 rounded bg-muted">
                  <div>Price after Potential Incentives</div>
                  <div className="ms-auto">
                    <span>
                      $
                      <h4 className="d-inline text-bold">
                        <CountUp
                          end={systemCost - federalIncentive}
                          duration={1}
                          separator=","
                          decimal="."
                        />
                      </h4>
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-3 px-2">
                  <span className="text-medium text-muted">
                    Est. lifetime savings $
                    <CountUp
                      end={lifetimeSavings}
                      duration={1}
                      separator=","
                      decimal="."
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4 mt-lg-0">
            <div
              className="card rounded h-100"
              style={{ backgroundColor: "#f8fafc" }}
            >
              <div className="card-body py-4">
                <div style={{ marginBottom: "10px" }}>
                  <input
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      outlineColor: "#FC007F",
                    }}
                    type="text"
                    placeholder="Full Name"
                  />
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <input
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      outlineColor: "#FC007F",
                    }}
                    type="text"
                    placeholder="Email"
                  />
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <input
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      outlineColor: "#FC007F",
                    }}
                    type="text"
                    placeholder="Bank Account"
                  />
                </div>
                <img
                  src="/assets/images/mock-banks.png"
                  alt="banks"
                  style={{ width: "100%" }}
                />
                <div
                  style={{
                    marginTop: "3px",
                    marginLeft: "2px",
                    fontSize: "small",
                    textAlign: "left",
                  }}
                >
                  <span style={{ color: "#0570de" }}>
                    Enter bank details manually instead
                  </span>
                  <span> (takes 1-2 business days)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right mt-4 mb-8">
          <button
            type="submit"
            className="btn btn-primary w-100 w-lg-25 font-weight-600"
            disabled
          >
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};
