import { ReactNode } from "react";
import { atom } from "recoil";
import { Step } from "../../types/steps";
import { StepperStatusSteps, ToastData } from "../../types/ui";

export const toastDataAtom = atom<ToastData[]>({
  key: "toastDataAtom",
  default: [],
});

export const isModalOpenAtom = atom<boolean>({
  key: "isModalOpenAtom",
  default: false,
});

export const isAppLoadingAtom = atom<boolean>({
  key: "isAppLoadingAtom",
  default: false,
});

export const isInDemoMode = atom<boolean>({
  key: "isInDemoMode",
  default: false,
});

export const modalContentAtom = atom<{
  header?: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
  width?: number;
} | null>({
  key: "modalContentAtom",
  default: null,
});

export const contextProposalStepperSteps = atom<Step[]>({
  key: "contextProposalStepperSteps",
  default: [
    { title: "Prequalify", stepStatus: StepperStatusSteps.NEUTRAL },
    { title: "Choose Plan", stepStatus: StepperStatusSteps.NEUTRAL },
    { title: "Plan's Details", stepStatus: StepperStatusSteps.NEUTRAL },
    { title: "Payment Details", stepStatus: StepperStatusSteps.NEUTRAL },
  ],
});
