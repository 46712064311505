import { atom } from "recoil";
import { Installer } from "../../types/installers";
import { AppSettings, UsaState } from "../../types/app-settings";
import { LoanDetails, PublicProposal } from "../../types/proposal";
import { CalculatedPlansAndSavings } from "../../types/plans";
import { ConfirmationResult } from "firebase/auth";
import { SetupIntent } from "@stripe/stripe-js";
import { SalesRep } from "../../types/salesRep";

export const contextInstallerIdAtom = atom<string | null>({
  key: "contextInstallerIdAtom",
  default: null,
});

export const contextInstallerAtom = atom<Installer | null>({
  key: "contextInstallerAtom",
  default: null,
});

export const contextProposalIdAtom = atom<string | null>({
  key: "contextProposalIdAtom",
  default: null,
});

export const contextProposalAtom = atom<PublicProposal | null>({
  key: "contextProposalAtom",
  default: null,
});

export const contextSaleRepAtom = atom<SalesRep | null>({
  key: "contextSaleRepAtom",
  default: null,
});

export const contextStateIncentiveAtom = atom<number>({
  key: "contextStateIncentiveAtom",
  default: 0,
});

export const contextITCRateAtom = atom<number>({
  key: "contextITCRateAtom",
  default: 0.3,
});

export const contextElectricityAccelerationAtom = atom<number>({
  key: "contextElectricityAccelerationAtom",
  default: 0.203,
});

export const isLeasePlanHiddenAtom = atom<boolean>({
  key: "isLeasePlanHiddenAtom",
  default: false,
});

export const downPaymentMinimumPercentAtom = atom<number>({
  key: "downPaymentMinimumPercentAtom",
  default: 0,
});

export const downPaymentMinimumAtom = atom<number>({
  key: "downPaymentMinimumAtom",
  default: 0,
});

export const downpaymentCalculationsAtom = atom<CalculatedPlansAndSavings[]>({
  key: "downpaymentCalculationsAtom",
  default: [],
});

export const calculatedLoanDetailsAtom = atom<LoanDetails | null>({
  key: "calculatedLoanDetailsAtom",
  default: null,
});

export const isCalculatedLoanFirstTimeAtom = atom<boolean>({
  key: "isCalculatedLoanFirstTimeAtom",
  default: true,
});

export const isLoanRecalculationErrorAtom = atom<boolean>({
  key: "isLoanRecalculationErrorAtom",
  default: false,
});

export const appSettingsAtom = atom<AppSettings>({
  key: "appSettingsAtom",
  default: {
    allowBattery: false,
    discountAlertThreshold: 0.03,
    discountLockThreshold: 0.05,
    downPaymentDefault: 0,
    downPaymentStep: 0.01,
  },
});

export const enabledStatesAtom = atom<UsaState[]>({
  key: "enabledStatesAtom",
  default: [],
});

export const hasOtpBeenSentAtom = atom<boolean>({
  key: "hasOtpBeenSentAtom",
  default: false,
});

export const confirmationResultAtom = atom<ConfirmationResult | null>({
  key: "confirmationResultAtom",
  default: null,
});

export const stripeSetupIntentAtom = atom<SetupIntent | null>({
  key: "stripeSetupIntentAtom",
  default: null,
});

export const isItcOnAtom = atom<boolean>({
  key: "isItcOnAtom",
  default: true,
});

export const loanCreditUrlAtom = atom<string | null>({
  key: "loanCreditUrlAtom",
  default: null,
});

export const stripeClientSecretAtom = atom<string | null>({
  key: "stripeClientSecretAtom",
  default: null,
});

export const canQualifyForLoan = atom<boolean>({
  key: "isNotQualified",
  default: true,
});

export const isArrayNotFound = atom<boolean>({
  key: "isArrayNotFound",
  default: false,
});
