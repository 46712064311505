/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  Fragment,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import CountUp from "react-countup";
import Lottie from "lottie-react";
import { Tooltip } from "react-tippy";

import Slider from "../react-rangeslider/lib/index";
import closeIcon from "../../assets/images/close-icon.svg";

import {
  getFormulaParam,
  getLoanCalculation,
  getLoanCreditUrl,
  getLoanDisclosures,
  unlockLoans,
} from "../../services/firebase";
import { isOlderThan, isValidDate } from "../../services/utils";

import swipeXAnimation from "../../assets/animations/swipe-x.json";
import loanUnlockModalIcon from "../../assets/images/loan-unlock-modal-icon.png";
import loader from "../../assets/images/loader-white.gif";
import planIndependent from "../../assets/images/plans/plan-v2-independent.svg";
import planSafeAndSound from "../../assets/images/plans/plan-v2-safe-and-sound.svg";
import planSelfMade from "../../assets/images/plans/plan-v2-self-made.svg";
import plusImg from "../../assets/images/white-plus-on-grey.svg";
import minusImg from "../../assets/images/white-minus-on-grey.svg";

import holdingHeartsImg from "../../assets/images/holding-hearts.svg";

import { DealPlans } from "../../types/deal";
import {
  STRIPE_ACCOUNT_STATUSES,
  SUNLIGHT_ACCOUNT_STATUSES,
} from "../../types/installers";
import { WindowBreakPoints } from "../../types/ui";

import {
  appSettingsAtom,
  calculatedLoanDetailsAtom,
  contextITCRateAtom,
  contextElectricityAccelerationAtom,
  contextProposalAtom,
  contextProposalIdAtom,
  downpaymentCalculationsAtom,
  downPaymentMinimumPercentAtom,
  enabledStatesAtom,
  isCalculatedLoanFirstTimeAtom,
  isItcOnAtom,
  isLeasePlanHiddenAtom,
  isLoanRecalculationErrorAtom,
  loanCreditUrlAtom,
  canQualifyForLoan,
} from "../../state/atoms/global";
import {
  customerDetailsStateAtom,
  isCustomerAgreedToLoanUnlockTermsAtom,
  isLoanUnlockedAtom,
  step1DownpaymentAtom,
  step1DownpaymentPercentAtom,
  step1SelectedPlanAtom,
} from "../../state/atoms/form";
import {
  isModalOpenAtom,
  modalContentAtom,
  isInDemoMode,
} from "../../state/atoms/ui";
import useWindowDimensions from "../../hooks";

import { Tag, TagType } from "../Tag";
import { Loading } from "../Loading";
import { LoanDetails, SunlightDisclosureType } from "../../types/proposal";
import { generateSunlightResponse } from "../../types/demo-data";
import { LOAN_TERMS } from "../../types/plans";
import CustomTooltip from "../CustomTooltip";
import TooltipIcon from "../TooltipIcon";
import { getPlansTooltipMessagesSelector } from "../../state/selectors/form";

function toggleSliderTooltip(show: boolean) {
  const tooltip = document.getElementsByClassName(
    "rangeslider__handle-tooltip"
  );
  if (tooltip?.length) {
    const classes = tooltip[0].classList;
    if (show) {
      classes.contains("hide") && classes.remove("hide");
    } else {
      !classes.contains("hide") && classes.add("hide");
    }
  }
}

const getSsnValue = (ssn: string) => {
  return ssn.length > 5
    ? ssn.replace(/([0-9]{3})([0-9]{2})([0-9]{1,4})/, "$1-$2-$3")
    : ssn.replace(/([0-9]{3})([0-9]{1,2})/, "$1-$2");
};

const getBirthDateValue = (birthDate: string) => {
  return birthDate.length > 4
    ? birthDate.replace(/([0-9]{2})([0-9]{2})([0-9]{1,4})/, "$1-$2-$3")
    : birthDate.replace(/([0-9]{2})([0-9]{1,2})/, "$1-$2");
};

const Step2: FunctionComponent<{}> = () => {
  const { width } = useWindowDimensions();
  const { search } = useLocation();
  const history = useHistory();

  const { discountLockThreshold, downPaymentDefault, downPaymentStep } =
    useRecoilValue(appSettingsAtom);
  const demoMode = useRecoilValue(isInDemoMode);
  const isLoanEnabled = useRecoilValue(canQualifyForLoan);
  const contextProposal = useRecoilValue(contextProposalAtom);
  const contextProposalId = useRecoilValue(contextProposalIdAtom);
  const enabledStates = useRecoilValue(enabledStatesAtom);
  const isLeasePlanHidden = useRecoilValue(isLeasePlanHiddenAtom);

  const customerDetailsState = useRecoilValue(customerDetailsStateAtom);

  const downpaymentCalculations = useRecoilValue(downpaymentCalculationsAtom);
  const setLoanCreditUrl = useSetRecoilState(loanCreditUrlAtom);
  const setITCRate = useSetRecoilState(contextITCRateAtom);
  const setElectricityAcceleration = useSetRecoilState(
    contextElectricityAccelerationAtom
  );

  const {
    calculatedPlans: { payNow, systemCost },
    savingDetails: { payNow: payNowSaving, fixed: fixedSaving },
    deal: { systemDetails },
    installer: {
      stripeAccountStatus: installerStripeAccountStatus,
      sunlightAccountStatus,
    },
    isSunlightPreQualified,
  } = contextProposal || {
    calculatedPlans: {},
    savingDetails: {},
    deal: {},
    calculatedBattery: {},
    installer: {},
  };

  const [fixedState, setFixedState] = useState(0);
  const [payNowState, setPayNowState] = useState(0);

  const { lifeTimeSavings: payNowLifeTimeSavings } = payNowSaving || {
    lifeTimeSavings: 0,
    percentage: 0,
  };
  const {
    lifeTimeSavings: fixedLifeTimeSavings,
    percentage: fixedPercentageSavings,
  } = fixedSaving || { lifeTimeSavings: 0, percentage: 0 };

  const [fixedLifeTimeSavingsState, setFixedLifeTimeSavingsState] =
    useState(fixedLifeTimeSavings);
  const [fixedPercentageSavingsState, setFixedPercentageSavingsState] =
    useState(fixedPercentageSavings);

  const { systemSize, estProduction, utilityRate } = systemDetails || {
    systemSize: 0,
    estProduction: 0,
    utilityRate: 0,
  };

  const [showDetails, setShowDetails] = useState(false);
  const setSelectedPlan = useSetRecoilState(step1SelectedPlanAtom);
  const plansTooltipMessages = useRecoilValue(getPlansTooltipMessagesSelector);
  const [downpaymentString, setDownpaymentString] = useState("0");

  const [showSwipeAnimation, setShowSwipeAnimation] = useState(true);
  const [fadeSwipeAnimation, setFadeSwipeAnimation] = useState(true);

  const setModalOpen = useSetRecoilState(isModalOpenAtom);
  const setModalContent = useSetRecoilState(modalContentAtom);

  const downpaymentStep = (payNow || 1) * downPaymentStep;
  const calculatedDownpaymentDefault = (payNow || 1) * downPaymentDefault;
  const downPaymentMinimumPercent = useRecoilValue(
    downPaymentMinimumPercentAtom
  );
  const downpaymentMinimum = (payNow || 1) * (downPaymentMinimumPercent / 100);
  const [downpaymentChanged, setDownpaymentChanged] = useState(false);
  const [downpayment, setDownpayment] = useState(
    Math.max(downpaymentMinimum, calculatedDownpaymentDefault)
  );
  const [globalDownpayment, setDownpaymentGlobalState] =
    useRecoilState(step1DownpaymentAtom);
  const [downpaymentPercent, setDownpaymentPercent] = useRecoilState(
    step1DownpaymentPercentAtom
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowSwipeAnimation(false);
    }, 7000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeSwipeAnimation(false);
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (width >= WindowBreakPoints.md) {
      setSelectedPlan(DealPlans.FIXED);
    } else {
      setSelectedPlan(null);
    }
  }, [width]);

  useEffect(() => {
    const newDownpaymentPercent = Math.round(
      (downpayment / (systemCost || 1)) * 100
    );

    setDownpaymentPercent(newDownpaymentPercent);
    setDownpaymentString(
      `${Math.round(downpayment || 0)?.toLocaleString("en", {
        useGrouping: true,
      })}`
    );
    setFixedState(
      downpaymentCalculations?.[newDownpaymentPercent]?.calculatedPlans.fixed
    );
    setPayNowState(
      downpaymentCalculations?.[newDownpaymentPercent]?.calculatedPlans.payNow
    );
    setFixedLifeTimeSavingsState(
      downpaymentCalculations?.[newDownpaymentPercent]?.savingDetails.fixed
        ?.lifeTimeSavings || 0
    );
    setFixedPercentageSavingsState(
      downpaymentCalculations?.[newDownpaymentPercent]?.savingDetails.fixed
        ?.percentage || 0
    );

    if (downpayment === 0) {
      toggleSliderTooltip(false);
    } else {
      toggleSliderTooltip(true);
    }

    setDownpaymentGlobalState(downpayment);
  }, [downpayment, downpaymentCalculations, contextProposal]);

  useEffect(() => {
    async function fetchFormulaParams() {
      const [itcRate, electricityAcceleration] = await Promise.all([
        getFormulaParam("ITC_RATE"),
        getFormulaParam("YEARLY_INCREACE_IN_ELECTRICITY"),
      ]);

      setITCRate(itcRate);
      setElectricityAcceleration(electricityAcceleration);
    }
    fetchFormulaParams();
  }, []);

  const [selectedLoanTerm, setSelectedLoanTerm] = useState<LOAN_TERMS>(
    LOAN_TERMS.L25
  );

  const getLoanDealPlan = (loanTerm: LOAN_TERMS) => {
    return loanTerm === LOAN_TERMS.L12
      ? DealPlans.LOAN12
      : loanTerm === LOAN_TERMS.L15
      ? DealPlans.LOAN15
      : loanTerm === LOAN_TERMS.L20
      ? DealPlans.LOAN20
      : DealPlans.LOAN25;
  };

  const [calculatedLoanDetails, setCalculatedLoanDetails] = useRecoilState(
    calculatedLoanDetailsAtom
  );
  const [isCalculatedLoanFirstTime, setIsCalculatedLoanFirstTime] =
    useRecoilState(isCalculatedLoanFirstTimeAtom);
  const [isLoanUnlocked, setIsLoanUnlocked] =
    useRecoilState(isLoanUnlockedAtom);
  const [isRecalculatingLoan, setIsRecalculatingLoan] = useState(false);
  const [isLoanRecalculationRequired, setIsLoanRecalculationRequired] =
    useState(!isCalculatedLoanFirstTime);
  const [isLoanRecalculationError, setIsLoanRecalculationError] =
    useRecoilState(isLoanRecalculationErrorAtom);
  const [isItcOn, setIsItcOn] = useRecoilState(isItcOnAtom);

  useEffect(() => {
    setIsLoanUnlocked(demoMode || !!isSunlightPreQualified);
  }, [isSunlightPreQualified]);

  useEffect(() => {
    setIsLoanRecalculationError(false);
    setIsLoanRecalculationRequired(!isCalculatedLoanFirstTime);
  }, [downpayment, isCalculatedLoanFirstTime]);

  useEffect(() => {
    setIsLoanRecalculationError(false);
    setIsLoanRecalculationRequired(true);
  }, [selectedLoanTerm]);

  const recalculateLoan = useCallback(async () => {
    if (!isLoanEnabled) {
      return;
    }

    setIsRecalculatingLoan(true);

    const newDownpaymentPercent = Math.round(
      (downpayment / (systemCost || 1)) * 100
    );

    if (demoMode) {
      const fixedMonthlyPayment =
        downpaymentCalculations?.[newDownpaymentPercent]?.calculatedPlans.fixed;

      setCalculatedLoanDetails(
        generateSunlightResponse({
          fixedMonthlyPayment,
          saving: fixedLifeTimeSavings,
          term: selectedLoanTerm,
        }).result.loan
      );
      setIsLoanRecalculationRequired(false);
      setIsLoanRecalculationError(false);
      setIsRecalculatingLoan(false);
      return;
    }

    try {
      const { result, status } = await getLoanCalculation({
        proposalId: contextProposalId || "",
        downPaymentPercent: newDownpaymentPercent,
        term: parseInt(selectedLoanTerm),
      });

      if (status === 200 && result?.loan) {
        const { loan } = result;
        setCalculatedLoanDetails(loan);
        setIsLoanRecalculationRequired(false);
        setIsLoanRecalculationError(
          loan.monthlyPayment === 0 || loan.escalatedMonthlyPayment === 0
        );
      } else {
        setIsLoanRecalculationError(true);
      }
    } catch (error) {
      setIsLoanRecalculationError(true);
    } finally {
      setIsRecalculatingLoan(false);
    }
  }, [downpayment, selectedLoanTerm]);

  useEffect(() => {
    recalculateLoan();
  }, []);

  type PlanCardDetails = {
    order: number;
    hidden?: boolean;
    failed?: boolean;
    recommended?: boolean;
    dealPlan: DealPlans;
    imgSrc: string;
    title: string;
    subtitle: string;
    details: React.ReactNode;
    cta: React.ReactNode;
    retryCta?: React.ReactNode;
    sideBySideFeatures: {
      left: React.ReactNode;
      right: React.ReactNode;
      isLocked?: boolean;
    }[];
    savings: React.ReactNode;
  };

  const isPayNowHidden = () => {
    if (demoMode && contextProposal?.selectedPlansToDisplay.buy) {
      return false;
    }

    if (!contextProposal?.selectedPlansToDisplay.buy) {
      return true;
    }

    if (
      installerStripeAccountStatus !== STRIPE_ACCOUNT_STATUSES.DETAILS_SUBMITTED
    ) {
      return true;
    }

    return false;
  };

  const isLeaseHidden = () => {
    if (!contextProposal?.selectedPlansToDisplay.lease) {
      return true;
    }

    if (isLeasePlanHidden) {
      return true;
    }

    if (
      !enabledStates
        .filter((state) => state.leaseEnabled)
        .find((state) => state.abbreviation === customerDetailsState)
    ) {
      return true;
    }

    if (fixedPercentageSavingsState * 1e2 < discountLockThreshold) {
      return true;
    }

    return false;
  };

  const isLoanHidden = (): boolean => {
    if (demoMode && contextProposal?.selectedPlansToDisplay.loan) {
      return false;
    }

    if (!contextProposal?.selectedPlansToDisplay.loan) {
      return true;
    }

    if (
      isLoanEnabled &&
      sunlightAccountStatus !== SUNLIGHT_ACCOUNT_STATUSES.VERIFIED
    ) {
      return true;
    }

    if (!isLoanEnabled) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (isPayNowHidden() && isLeaseHidden() && isLoanHidden()) {
      history.push(`/no-plans-found?proposalId=${contextProposalId}`);
    }
  }, []);

  const plans: PlanCardDetails[] = [
    {
      order: 0,
      hidden: isPayNowHidden(),
      dealPlan: DealPlans.PAY_NOW,
      imgSrc: planIndependent,
      title: "Independent",
      subtitle:
        "Save big on utility charges while benefiting from tax incentives",
      details: (
        <>
          <div className="d-flex align-items-end">
            <div className="flex-grow-1">
              <div className="text-small text-muted mt-1">&nbsp;</div>
              <h4>$</h4>
              <h2>
                {downpaymentChanged ? (
                  downpaymentPercent === 100 ? (
                    0
                  ) : (
                    payNowState?.toLocaleString("en", {
                      useGrouping: true,
                    })
                  )
                ) : (
                  <CountUp
                    end={downpaymentPercent === 100 ? 0 : payNowState || 0}
                    duration={1}
                    separator=","
                    decimal="."
                  />
                )}
              </h2>
            </div>
            <div className="mb-05">
              <Tag tagType={TagType.buy}>BUY</Tag>
            </div>
          </div>
        </>
      ),
      cta: (
        <button
          className={`w-100 btn ${
            isSubmitting ? "btn-primary" : "btn-outline-primary"
          } btn-outline-primary-fill-hover py-05`}
          style={{
            marginTop: "0.75rem",
          }}
          disabled={isSubmitting}
          onClick={async () => {
            if (!isSubmitting) {
              setIsSubmitting(true);
              setSelectedPlan(DealPlans.PAY_NOW);
              history.push(`/step-3${search}`);
            }
          }}
        >
          <span className="text-small">
            {isSubmitting ? (
              <img height="10px" src={loader} alt="Submitting..." />
            ) : (
              "Get Started"
            )}
          </span>
        </button>
      ),
      sideBySideFeatures: [
        {
          left: "Payments",
          right: "Milestones",
        },
      ],
      savings: (
        <Tooltip
          html={
            <CustomTooltip className="max-w-400px">
              {plansTooltipMessages.buy}
            </CustomTooltip>
          }
          size="small"
          position="bottom"
          trigger={width >= WindowBreakPoints.md ? "mouseenter" : "click"}
          animation="shift"
          transitionFlip={false}
        >
          <div className="text-medium d-flex gap-1">
            <span className="no-wrap">Est. lifetime savings: </span>
            <span className="no-wrap">
              $
              {Math.round(payNowLifeTimeSavings).toLocaleString("en", {
                useGrouping: true,
              })}
            </span>
            <span className="ml-05 line-height-1" style={{ marginTop: "2px" }}>
              <TooltipIcon />
            </span>
          </div>
        </Tooltip>
      ),
    },
    {
      order: 2,
      hidden: isLeaseHidden(),
      dealPlan: DealPlans.FIXED,
      imgSrc: planSafeAndSound,
      title: "Safe & Sound",
      subtitle: "Get the full service at a fixed price, and still save money",
      details: (
        <>
          <div className="d-flex align-items-end">
            <div className="flex-grow-1">
              <div className="text-small text-muted mt-1">&nbsp;</div>
              <h4>$</h4>
              <h2>
                {downpaymentChanged ? (
                  downpaymentPercent === 100 ? (
                    0
                  ) : (
                    fixedState?.toLocaleString("en", {
                      useGrouping: true,
                    })
                  )
                ) : (
                  <CountUp
                    end={downpaymentPercent === 100 ? 0 : fixedState || 0}
                    duration={1}
                    separator=","
                    decimal="."
                  />
                )}
              </h2>
              <span className="ml-025 text-medium text-muted">/monthly</span>
            </div>
            <div className="mb-05">
              <Tag tagType={TagType.lease}>LEASE</Tag>
            </div>
          </div>
        </>
      ),
      cta: (
        <button
          className={`mt-1 w-100 btn ${
            isSubmitting ? "btn-primary" : "btn-outline-primary"
          } btn-outline-primary-fill-hover py-05`}
          disabled={isSubmitting}
          onClick={async () => {
            if (!isSubmitting) {
              setIsSubmitting(true);
              setSelectedPlan(DealPlans.PAY_AS_YOU_GO);
              history.push(`/step-3${search}`);
            }
          }}
        >
          <span className="text-small">
            {isSubmitting ? (
              <img height="10px" src={loader} alt="Submitting..." />
            ) : (
              "Get Started"
            )}
          </span>
        </button>
      ),
      sideBySideFeatures: [
        {
          left: "Term",
          right: "25 years",
        },
        {
          left: "Buyout",
          right: "Anytime",
        },
        {
          left: "Rate",
          right: "Fixed",
        },
      ],
      savings: (
        <Tooltip
          html={
            <CustomTooltip className="max-w-400px">
              {plansTooltipMessages.lease}
            </CustomTooltip>
          }
          size="small"
          position="bottom"
          trigger={width >= WindowBreakPoints.md ? "mouseenter" : "click"}
          animation="shift"
          transitionFlip={false}
        >
          <div className="text-medium d-flex gap-1">
            <span className="no-wrap">Est. lifetime savings: </span>
            <span className="no-wrap">
              $
              {Math.round(fixedLifeTimeSavingsState).toLocaleString("en", {
                useGrouping: true,
              })}
            </span>
            <span className="ml-05 line-height-1" style={{ marginTop: "2px" }}>
              <TooltipIcon />
            </span>
          </div>
        </Tooltip>
      ),
    },
    {
      order: 1,
      hidden: isLoanHidden(),
      failed: isLoanRecalculationError,
      dealPlan: getLoanDealPlan(selectedLoanTerm),
      imgSrc: planSelfMade,
      title: "Self Made",
      subtitle: "Spread the load and enjoy the tax benefits",
      details: (
        <div>
          <div className="text-small pt-1 text-muted">
            {!isLoanUnlocked ? "From" : <>&nbsp;</>}
          </div>
          <div className="d-flex align-items-end">
            <div className="flex-grow-1">
              <h4
                className={
                  isRecalculatingLoan || isLoanRecalculationRequired
                    ? "text-muted"
                    : ""
                }
              >
                $
              </h4>
              {isRecalculatingLoan || isLoanRecalculationRequired ? (
                <h3
                  className="mt-025 skeleton-text"
                  style={{ marginBottom: "0.2rem" }}
                >
                  XXXX
                </h3>
              ) : (
                <h2>
                  {downpaymentChanged ? (
                    downpaymentPercent === 100 ? (
                      0
                    ) : (
                      (isItcOn
                        ? calculatedLoanDetails?.monthlyPayment || 0
                        : calculatedLoanDetails?.escalatedMonthlyPayment || 0
                      ).toLocaleString("en", {
                        useGrouping: true,
                        maximumFractionDigits: 0,
                      })
                    )
                  ) : (
                    <CountUp
                      start={
                        isItcOn
                          ? calculatedLoanDetails?.monthlyPayment || 0
                          : calculatedLoanDetails?.escalatedMonthlyPayment || 0
                      }
                      end={
                        isItcOn
                          ? calculatedLoanDetails?.monthlyPayment || 0
                          : calculatedLoanDetails?.escalatedMonthlyPayment || 0
                      }
                      duration={0.75}
                      separator=","
                      decimal="."
                    />
                  )}
                  {isLoanUnlocked && (
                    <span className="position-absolute">*</span>
                  )}
                </h2>
              )}
              <span
                className={`${
                  isLoanUnlocked &&
                  !isRecalculatingLoan &&
                  !isLoanRecalculationRequired
                    ? "ml-2"
                    : "ml-025"
                } text-medium text-muted`}
              >
                /monthly
              </span>
            </div>
            <div className="mb-05">
              <Tag tagType={TagType.loan}>LOAN</Tag>
            </div>
          </div>
        </div>
      ),
      cta: isLoanRecalculationRequired ? (
        <button
          style={{ marginTop: "0.5rem" }}
          className={`w-100 btn btn-secondary py-05`}
          disabled={isRecalculatingLoan}
          onClick={async () => {
            await recalculateLoan();
          }}
        >
          <span className="text-small">
            {isRecalculatingLoan ? (
              <img
                height="10px"
                style={{ marginTop: "-0.25rem" }}
                src={loader}
                alt="Recalculaing..."
              />
            ) : (
              "Recalculate"
            )}
          </span>
        </button>
      ) : isLoanUnlocked ? (
        <button
          className={`mt-1 w-100 btn ${
            isSubmitting ? "btn-primary" : "btn-outline-primary"
          } btn-outline-primary-fill-hover py-05`}
          disabled={isSubmitting}
          onClick={async () => {
            if (!isSubmitting) {
              setSelectedPlan(getLoanDealPlan(selectedLoanTerm));
              setIsSubmitting(true);
              try {
                if (contextProposalId) {
                  if (demoMode) {
                    setLoanCreditUrl("some-url");
                    history.push(`/step-3${search}`);
                    return;
                  }
                  const { result, status } = await getLoanCreditUrl({
                    proposalId: contextProposalId,
                  });
                  if (status === 200 && result?.creditUrl) {
                    setLoanCreditUrl(result.creditUrl);
                    history.push(`/step-3${search}`);
                    return;
                  } else {
                    console.error("Error applying for loan");
                    setIsLoanRecalculationError(true);
                  }
                }
              } catch (err) {
                console.error(`Error: ${JSON.stringify(err)}`);
                setIsLoanRecalculationError(true);
              } finally {
                setIsSubmitting(false);
              }
            }
          }}
        >
          <span className="text-small">
            {isSubmitting ? (
              <img height="10px" src={loader} alt="Submitting..." />
            ) : (
              "Get Started"
            )}
          </span>
        </button>
      ) : (
        <div
          className={`mt-1 w-100 btn btn-secondary py-05`}
          role="button"
          onClick={() => {
            setModalContent({
              body: <UnlockLoanModalBody />,
              width: 700,
            });
            setModalOpen(true);
          }}
        >
          <span className="text-small">Unlock Your Rate</span>
        </div>
      ),
      retryCta: (
        <button
          className={`w-100 btn ${
            isRecalculatingLoan ? "btn-primary" : "btn-outline-primary"
          } btn-outline-primary-fill-hover py-05`}
          disabled={isRecalculatingLoan}
          onClick={async () => {
            await recalculateLoan();
          }}
        >
          <span className="text-small">
            {isRecalculatingLoan ? (
              <img height="10px" src={loader} alt="Submitting..." />
            ) : (
              "Try Again"
            )}
          </span>
        </button>
      ),
      sideBySideFeatures: isLoanUnlocked
        ? [
            {
              left: "APR",
              right: (calculatedLoanDetails?.apr || 0).toFixed(2) + "%",
            },
            {
              left: "Term (years)",
              right: (
                <div style={{ marginTop: "-0.3rem" }}>
                  <select
                    className="form-control form-control-xs"
                    value={selectedLoanTerm}
                    onChange={(e) => {
                      setSelectedLoanTerm(e.target.value as LOAN_TERMS);
                    }}
                  >
                    <option value={LOAN_TERMS.L12}>{LOAN_TERMS.L12}</option>
                    <option value={LOAN_TERMS.L15}>{LOAN_TERMS.L15}</option>
                    <option value={LOAN_TERMS.L20}>{LOAN_TERMS.L20}</option>
                    <option value={LOAN_TERMS.L25}>{LOAN_TERMS.L25}</option>
                  </select>
                </div>
              ),
            },
            {
              left: (
                <ItcPaydownTooltip term={parseInt(selectedLoanTerm)}>
                  <div className={`text-medium d-flex align-items-center`}>
                    <span className="no-wrap">ITC Paydown</span>
                    <span className="ml-1">
                      <TooltipIcon />
                    </span>
                  </div>
                </ItcPaydownTooltip>
              ),
              right: (
                <>
                  <input
                    className="toggle-checkbox"
                    id="itc-checkbox"
                    type="checkbox"
                    checked={isItcOn}
                    onChange={() => {
                      setIsItcOn(!isItcOn);
                    }}
                  />
                  <label
                    className="toggle-checkbox-label"
                    htmlFor="itc-checkbox"
                  >
                    <span className="toggle-checkbox-button" />
                  </label>
                </>
              ),
            },
          ]
        : [
            {
              left: "APR",
              right: "Locked",
              isLocked: true,
            },
            {
              left: "Term (years)",
              right: "Locked",
              isLocked: true,
            },
            {
              left: "ITC Paydown",
              right: "Locked",
              isLocked: true,
            },
          ],
      savings: isLoanRecalculationRequired ? (
        <div className={`text-medium d-flex gap-1 skeleton-text`}>
          <span className="no-wrap">Est. lifetime savings: </span>
          <span className="no-wrap">Locked</span>
        </div>
      ) : (
        <Tooltip
          html={
            <CustomTooltip className="max-w-400px">
              {plansTooltipMessages.loan}
            </CustomTooltip>
          }
          size="small"
          position="bottom"
          trigger={width >= WindowBreakPoints.md ? "mouseenter" : "click"}
          animation="shift"
          transitionFlip={false}
        >
          <div
            className={`text-medium d-flex gap-1 ${
              isLoanUnlocked ? "" : "text-disabled"
            }`}
          >
            <span className="no-wrap">Est. lifetime savings: </span>
            <span className="no-wrap">
              $
              {Math.round(
                calculatedLoanDetails?.saving.lifeTimeSavings || 0
              ).toLocaleString("en", {
                useGrouping: true,
              })}
            </span>
            <span className="ml-05 line-height-1" style={{ marginTop: "2px" }}>
              <TooltipIcon />
            </span>
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <h3 className="d-block d-md-none font-weight-600">
        Go solar on your own terms
      </h3>
      <h1 className="d-none d-md-block">Go solar on your own terms</h1>
      <div className="section-dark mt-4">
        <div className="max-w-1100px mx-auto my-2 px-3 px-lg-1 pt-2 pb-1">
          <div className="d-flex mb-1">
            <div className="text-start flex-grow-1 pl-0 pr-1">
              <div className="m-0 text-medium" style={{ lineHeight: "1rem" }}>
                Get better rates with a down payment
              </div>
            </div>
            <div style={{ minWidth: "4.5rem" }} className="text-end">
              <span>
                <div className="d-inline font-weight-600 text-medium">$</div>
                <h5 className="d-inline data-hj-allow">{downpaymentString}</h5>
              </span>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 py-0 px-1 text-center"
              style={{ marginTop: "-0.5rem", marginBottom: "-1rem" }}
            >
              <Slider
                min={0}
                max={systemCost}
                value={downpayment}
                step={downpaymentStep}
                onChange={(value: number) => {
                  if (!downpaymentChanged) {
                    setDownpaymentChanged(true);
                    setIsCalculatedLoanFirstTime(false);
                  }
                  setDownpayment(Math.max(downpaymentMinimum, value));
                }}
                orientation="horizontal"
              />
            </div>
            <div className="col-6 m-0 py-0 px-1 text-start">
              <span className="text-small text-muted data-hj-allow">0%</span>
            </div>
            <div className="col-6 m-0 py-0 px-1 text-end">
              <span className="text-small text-muted data-hj-allow">100%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-1100px m-auto pb-5">
        <div className="w-100 m-auto">
          <div className="row mb-2">
            <div className="col-12 px-4 px-lg-2 text-start">
              <span
                className="text-medium cursor-pointer"
                role="button"
                onClick={() => setShowDetails(!showDetails)}
              >
                {!showDetails ? (
                  <>
                    <img
                      src={plusImg}
                      alt="Show system details"
                      width={16}
                      style={{ marginBottom: "2px" }}
                    />
                    <span className="ml-1">Show system details</span>
                  </>
                ) : (
                  <>
                    <img
                      src={minusImg}
                      alt="Hide system details"
                      width={16}
                      style={{ marginBottom: "2px" }}
                    />
                    <span className="ml-1">Hide system details</span>
                  </>
                )}
              </span>
            </div>
          </div>
          {showDetails && (
            <div className="px-3 px-lg-1">
              <div className="row text-start justify-content-between mt-1 mb-2 rhino_animate__animated rhino_animate__fadeIn">
                <div className="plan-container col-12 col-md-6 col-lg-3 px-2">
                  <div className="text-primary">System size</div>
                  <div>
                    <h2 className="data-hj-allow">
                      {systemSize?.toLocaleString("en", {
                        useGrouping: true,
                      })}
                    </h2>
                    <h4>kW</h4>
                  </div>
                </div>
                <div className="plan-container col-12 col-md-6 col-lg-3 px-2 pt-2 pt-md-0">
                  <div className="text-primary">Est. production</div>
                  <div>
                    <h2 className="data-hj-allow">
                      {estProduction?.toLocaleString("en", {
                        useGrouping: true,
                      })}
                    </h2>
                    <h4>kWh</h4>
                  </div>
                </div>
                <div className="plan-container col-12 col-md-6 col-lg-3 px-2 pt-2 pt-lg-0">
                  <div className="text-primary">Current utility rate</div>
                  <div>
                    <h4>¢</h4>
                    <h2 className="data-hj-allow">
                      {utilityRate?.toLocaleString("en", {
                        useGrouping: true,
                      })}
                    </h2>
                  </div>
                </div>
              </div>
              <hr className="d-none d-md-block" />
            </div>
          )}
        </div>
        <div
          className={`m-0 plan-container ${
            width < WindowBreakPoints.lg
              ? "pl-3 pr-1"
              : "row px-md-2 px-lg-0 d-flex justify-content-center"
          }`}
          style={
            width < WindowBreakPoints.lg
              ? {
                  whiteSpace: "nowrap",
                  overflowX: "scroll",
                  scrollbarWidth: "none",
                  position: "relative",
                }
              : {}
          }
        >
          {width < WindowBreakPoints.md && showSwipeAnimation && (
            <div
              className="position-absolute w-100 h-100 m-auto"
              style={{
                top: 0,
                left: 0,
                opacity: 0.25,
                zIndex: 10000,
              }}
              onClick={() => setShowSwipeAnimation(false)}
              role="button"
            >
              <div
                className={`swipe-animation-holder m-auto rhino_animate__animated ${
                  fadeSwipeAnimation
                    ? "rhino_animate__fadeIn"
                    : "rhino_animate__fadeOut"
                }`}
              >
                <Lottie animationData={swipeXAnimation} loop={true} />
              </div>
            </div>
          )}
          {plans
            .sort((a, b) => a.order - b.order)
            .map(
              (
                {
                  hidden,
                  failed,
                  recommended,
                  dealPlan,
                  imgSrc,
                  title,
                  subtitle,
                  details,
                  cta,
                  retryCta,
                  sideBySideFeatures,
                  savings,
                },
                index
              ) => {
                return (
                  <Fragment key={`plan-${index}`}>
                    {!hidden && (
                      <div
                        className={`${
                          width < WindowBreakPoints.lg
                            ? "m-0 mx-05"
                            : "col px-05 max-w-33"
                        } ${
                          index + 1 < plans.length ? "mr-2" : ""
                        } text-start plan w-100 w-lg-75 w-lg-100 data-hj-allow`}
                        style={
                          width < WindowBreakPoints.lg
                            ? {
                                minWidth: "75vw",
                                maxWidth: "75vw",
                                height: "100%",
                                display: "table-cell",
                                verticalAlign: "top",
                                margin: 0,
                                paddingRight: "1.5rem",
                              }
                            : {}
                        }
                      >
                        <div
                          className={`d-block border rounded plan border-1 border-muted ${
                            downpaymentPercent === 100
                              ? "opacity-50 cursor-arrow border-1 border-muted"
                              : ""
                          }`}
                          style={{
                            padding: "1.5rem 1.5rem 1.5rem 1.5rem",
                          }}
                        >
                          {failed ? (
                            <div className="text-center">
                              <div className="text-bold">
                                Something went wrong
                              </div>
                              <div className="text-medium mt-4 mb-2">
                                We couldn’t fetch the loan,
                                <br />
                                please try again in a few minutes.
                              </div>
                              <div
                                className="w-60 m-auto d-flex"
                                style={{
                                  minHeight: "15.75rem",
                                }}
                              >
                                <img
                                  className="w-100 m-auto"
                                  style={{
                                    maxHeight: "15rem",
                                    maxWidth: "15rem",
                                  }}
                                  src={holdingHeartsImg}
                                  alt=""
                                />
                              </div>
                              <div className="mt-2 mb-2 w-50 m-auto">
                                {retryCta}
                              </div>
                            </div>
                          ) : (
                            <>
                              {recommended && (
                                <div className="ribbon-wrapper">
                                  <div className="ribbon ribbon-recommended">
                                    &nbsp;&nbsp;Popular
                                  </div>
                                </div>
                              )}
                              <div className="pt-025 pr-3 pr-md-0">
                                <div
                                  className="d-flex m-0 p-0 align-items-center"
                                  style={{ minHeight: "1.2rem" }}
                                >
                                  <img src={imgSrc} alt={title} />
                                  <div className="ml-1 text-bold">{title}</div>
                                </div>
                              </div>
                              <div
                                className="text-medium text-muted mt-1 d-none d-md-block"
                                style={{ minHeight: "2.4rem" }}
                              >
                                {subtitle}
                              </div>
                              <div className={`w-100`}>
                                <div className="h-100">
                                  <div style={{ minHeight: "4rem" }}>
                                    {details}
                                  </div>
                                  <div style={{ minHeight: "2.75rem" }}>
                                    {cta}
                                  </div>
                                  <div
                                    className="mt-2"
                                    style={{ minHeight: "6.75rem" }}
                                  >
                                    {sideBySideFeatures &&
                                      sideBySideFeatures.length > 0 &&
                                      sideBySideFeatures.map(
                                        ({ left, right, isLocked }, index) => {
                                          return (
                                            <div
                                              key={`side-by-side-feature-${index}`}
                                              className={`d-flex py-1`}
                                            >
                                              <span
                                                className={`w-50 text-medium align-content-center ${
                                                  isLocked
                                                    ? "skeleton-text"
                                                    : ""
                                                }`}
                                              >
                                                <div>{left}</div>
                                              </span>
                                              <span
                                                className={`w-50 text-medium align-content-center text-end ${
                                                  isLocked
                                                    ? "skeleton-text"
                                                    : ""
                                                }`}
                                              >
                                                {right}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="mt-1">{savings}</div>
                            </>
                          )}
                          <div className={`footer ${dealPlan.toLowerCase()}`} />
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              }
            )}
        </div>
        {isLoanUnlocked && calculatedLoanDetails && (
          <div className="row px-4 px-lg-2 text-medium text-start text-muted mt-3">
            *{" "}
            <LoanPaydownDisclaimer
              term={parseInt(selectedLoanTerm)}
              calculatedLoanDetails={calculatedLoanDetails}
            />
          </div>
        )}
      </div>
    </>
  );
};

const UnlockLoanModalBody: FunctionComponent = () => {
  const contextProposal = useRecoilValue(contextProposalAtom);
  const contextProposalId = useRecoilValue(contextProposalIdAtom);
  const setIsLoanUnlockedAtom = useSetRecoilState(isLoanUnlockedAtom);
  const setModalContent = useSetRecoilState(modalContentAtom);
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const isCustomerAgreedToLoanUnlockTerms = useRecoilValue(
    isCustomerAgreedToLoanUnlockTermsAtom
  );
  const [isTermsChecked, setIsTermsChecked] = useRecoilState(
    isCustomerAgreedToLoanUnlockTermsAtom
  );
  const setIsLoanRecalculationError = useSetRecoilState(
    isLoanRecalculationErrorAtom
  );
  const [isLoading, setIsLoading] = useState(false);

  const [annualIncome, setAnnualIncome] = useState<number>();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [ssn, setSsn] = useState("");
  const [dateOfBirthValue, setDateOfBirthValue] = useState("");
  const [ssnValue, setSsnValue] = useState("");

  const [errors, setErrors] = useState<{
    ssn: null | string;
    dateOfBirth: null | string;
    annualIncome: null | string;
    termsCheckbox: null | string;
    global: null | string;
  }>({
    ssn: null,
    dateOfBirth: null,
    annualIncome: null,
    termsCheckbox: null,
    global: null,
  });
  const [incomeDisclosure, setIncomeDisclosure] = useState("");
  const [incomeTTDisclosure, setIncomeTTDisclosure] = useState("");
  const [submitForPrequalDisclosure, setSubmitForPrequalDisclosure] =
    useState("");
  const [phoneNumberSolarDisclosure, setPhoneNumberSolarDisclosure] =
    useState("");
  const [eSignSubmitDisclosure, setESignSubmitDisclosure] = useState("");
  const [eConsentDisclosure, setEConsentDisclosure] = useState("");
  const [eConsentDisclosureModalOpen, setEConsentDisclosureModalOpen] =
    useState(false);

  const { prospect } = contextProposal || {};
  const { phoneNumber } = prospect || {};

  useEffect(() => {
    const fetchDisclosures = async () => {
      setIsLoading(true);
      try {
        if (!contextProposalId) {
          return;
        }
        const { result, status } = await getLoanDisclosures({
          proposalId: contextProposalId,
        });
        if (status !== 200) {
          setErrors({
            ...errors,
            termsCheckbox: "Something went wrong. Please try again later.",
          });
          return;
        }

        result?.disclosures.map((disclosure) => {
          switch (disclosure.type) {
            case SunlightDisclosureType.Income: {
              setIncomeDisclosure(disclosure.disclosureText);
              break;
            }
            case SunlightDisclosureType.IncomeTT: {
              setIncomeTTDisclosure(disclosure.disclosureText);
              break;
            }
            case SunlightDisclosureType.SubmitForPrequal: {
              setSubmitForPrequalDisclosure(disclosure.disclosureText);
              break;
            }
            case SunlightDisclosureType.PhoneNumberSolar: {
              setPhoneNumberSolarDisclosure(disclosure.disclosureText);
              break;
            }
            case SunlightDisclosureType.ESignSubmit: {
              setESignSubmitDisclosure(disclosure.disclosureText);
              break;
            }
            case SunlightDisclosureType.eConsent: {
              setEConsentDisclosure(disclosure.disclosureText);
              break;
            }
            default:
              break;
          }
        });
      } catch (error) {
        setErrors({
          ...errors,
          termsCheckbox: "Something went wrong. Please try again later.",
        });
        setIsLoanRecalculationError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDisclosures();
  }, [contextProposalId]);

  useEffect(() => {
    setSsnValue(getSsnValue(ssn));

    const onlyNumsReg = new RegExp(/^\d+$/);
    setErrors((prevState) => ({
      ...prevState,
      ssn: ssn && !onlyNumsReg.test(ssn) ? "Enter numbers only" : null,
    }));
  }, [ssn]);

  useEffect(() => {
    setDateOfBirthValue(getBirthDateValue(dateOfBirth));
  }, [dateOfBirth]);

  useEffect(() => {
    if (isCustomerAgreedToLoanUnlockTerms)
      setErrors((prevState) => ({
        ...prevState,
        termsCheckbox: null,
      }));
  }, [isCustomerAgreedToLoanUnlockTerms]);

  const verifyAnnualIncome = () => {
    setErrors((prevState) => ({
      ...prevState,
      annualIncome:
        annualIncome && (annualIncome < 50000 || annualIncome > 500000)
          ? "Please check again"
          : null,
    }));
  };

  const verifyDateOfBirth = () => {
    if (!dateOfBirth) {
      setErrors((prevState) => ({
        ...prevState,
        dateOfBirth: null,
      }));
      return;
    }
    if (!isValidDate(dateOfBirth)) {
      setErrors((prevState) => ({
        ...prevState,
        dateOfBirth: "Please enter in MM-DD-YYYY format",
      }));
    } else if (!isOlderThan(dateOfBirth, 17)) {
      setErrors((prevState) => ({
        ...prevState,
        dateOfBirth: "To apply you must be 18 or older",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        dateOfBirth: null,
      }));
    }
  };

  const verifySsn = () => {
    if (!ssn) {
      setErrors((prevState) => ({
        ...prevState,
        ssn: null,
      }));
      return;
    }
    setErrors((prevState) => ({
      ...prevState,
      ssn: ssn.length !== 9 ? "Please check again" : prevState.ssn,
    }));
  };

  const submitLoanUnlockingRequest = async () => {
    setIsLoading(true);
    try {
      if (!contextProposalId) {
        return;
      }

      const birthDateSplit = dateOfBirthValue.split("-");
      const birthDate = `${birthDateSplit[2]}-${birthDateSplit[0]}-${birthDateSplit[1]}`;

      const { status } = await unlockLoans({
        proposalId: contextProposalId,
        ssn,
        birthDate,
        annualIncome: annualIncome || 0,
      });
      if (status !== 200) {
        setErrors({
          ...errors,
          global: "Something went wrong. Please try again later.",
        });
        setIsLoanRecalculationError(true);
        return;
      }
      setIsLoanUnlockedAtom(true);
      setModalContent(null);
      setIsModalOpen(false);
      setIsLoading(false);
    } catch (error) {
      setErrors({
        ...errors,
        termsCheckbox: "Something went wrong. Please try again later.",
      });
      setIsLoanRecalculationError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-100 px-4">
      {isLoading && <Loading isContained className="mb-2" />}
      <div className="text-center mb-1">
        <img src={loanUnlockModalIcon} alt="" />
      </div>
      <div className="text-center">We need a few more details...</div>
      <div className="text-center text-small text-muted">
        (This will NOT impact your credit score)
      </div>
      <form className="row">
        <div className="col-12 col-lg-6 pl-2 pl-md-1 mt-2">
          <div>
            <label className="text-medium" htmlFor="ssn">
              Social Security Number
              <span className="text-primary ml-025">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="ssn"
              value={ssnValue}
              onChange={(e) => {
                setSsn(e.target.value.replaceAll("-", ""));
                setErrors((prevState) => ({
                  ...prevState,
                  ssn: null,
                }));
              }}
              onBlur={() => {
                verifySsn();
              }}
            />
            {errors.ssn && (
              <div className="col-12 mt-1">
                <div className="pl-1 text-primary text-small">{errors.ssn}</div>
              </div>
            )}
          </div>
          <div className="mt-2">
            <label className="text-medium" htmlFor="dob">
              Date of Birth<span className="text-primary ml-025">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="dob"
              value={dateOfBirthValue}
              onChange={(e) => {
                setDateOfBirth(e.target.value.replaceAll("-", ""));
                setErrors((prevState) => ({
                  ...prevState,
                  dateOfBirth: null,
                }));
              }}
              onBlur={() => {
                verifyDateOfBirth();
              }}
            />
            <div className="col-12 mt-1">
              {errors.dateOfBirth && (
                <div className="pl-1 text-primary text-small">
                  {errors.dateOfBirth}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 pr-2 pr-md-1 mt-2">
          <div>
            <Tooltip
              html={
                <CustomTooltip className="p-2">
                  {incomeTTDisclosure}
                </CustomTooltip>
              }
            >
              <label className="text-medium" htmlFor="annual-income">
                Annual Income<span className="text-primary ml-025">*</span>
                <span
                  className="ml-05 line-height-1"
                  style={{ marginTop: "-1.75px" }}
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6" cy="6" r="6" fill="#AEB4C1" />
                    <path
                      d="M6.00419 3.89877C5.71918 3.89877 5.48484 3.81431 5.30117 3.64537C5.12383 3.46994 5.03516 3.25552 5.03516 3.00211C5.03516 2.74221 5.12383 2.52779 5.30117 2.35885C5.48484 2.18342 5.71918 2.0957 6.00419 2.0957C6.28287 2.0957 6.51088 2.18342 6.68822 2.35885C6.87189 2.52779 6.96373 2.74221 6.96373 3.00211C6.96373 3.25552 6.87189 3.46994 6.68822 3.64537C6.51088 3.81431 6.28287 3.89877 6.00419 3.89877ZM6.81172 4.46406V9.90251H5.18716V4.46406H6.81172Z"
                      fill="#ffffff"
                    />
                  </svg>
                </span>
              </label>
            </Tooltip>
            <input
              type="text"
              className="form-control"
              id="annual-income"
              value={
                annualIncome
                  ? `$${annualIncome.toLocaleString("en-US", {
                      useGrouping: true,
                    })}`
                  : ""
              }
              onChange={(e) => {
                setAnnualIncome(
                  parseFloat(
                    e.target.value.replaceAll("$", "").replaceAll(",", "")
                  )
                );
                setErrors((prevState) => ({
                  ...prevState,
                  annualIncome: null,
                }));
              }}
              onBlur={() => {
                verifyAnnualIncome();
              }}
            />
            <div className="col-12 mt-1">
              <div className="mt-1 text-muted text-small">
                {errors.annualIncome && (
                  <div className="text-primary mb-05">
                    {errors.annualIncome}
                  </div>
                )}
                {incomeDisclosure}
              </div>
            </div>
          </div>
          <div
            className="text-medium d-flex align-items-center"
            style={{ marginTop: "0.7rem" }}
          >
            <Tooltip
              html={
                <CustomTooltip className="p-2">
                  Sunlight will use the provided mobile phone number for account
                  management purposes.
                </CustomTooltip>
              }
            >
              <span>
                Your number
                <span
                  className="ml-05 line-height-1"
                  style={{ marginTop: "-1.75px" }}
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6" cy="6" r="6" fill="#AEB4C1" />
                    <path
                      d="M6.00419 3.89877C5.71918 3.89877 5.48484 3.81431 5.30117 3.64537C5.12383 3.46994 5.03516 3.25552 5.03516 3.00211C5.03516 2.74221 5.12383 2.52779 5.30117 2.35885C5.48484 2.18342 5.71918 2.0957 6.00419 2.0957C6.28287 2.0957 6.51088 2.18342 6.68822 2.35885C6.87189 2.52779 6.96373 2.74221 6.96373 3.00211C6.96373 3.25552 6.87189 3.46994 6.68822 3.64537C6.51088 3.81431 6.28287 3.89877 6.00419 3.89877ZM6.81172 4.46406V9.90251H5.18716V4.46406H6.81172Z"
                      fill="#ffffff"
                    />
                  </svg>
                </span>
              </span>
            </Tooltip>
            <span className="ml-2">{phoneNumber}</span>
            <span className="ml-1">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#FDF1F7" />
                <path
                  d="M9.23107 5.62145L8.91817 4.61362L6.50363 5.44276V2.76953H5.49697V5.44276L3.08243 4.61362L2.76953 5.62145L5.18407 6.45059L3.69454 8.60918L4.51071 9.23107L6.00025 7.07248L7.48978 9.23107L8.30595 8.60918L6.81642 6.45059L9.23107 5.62145Z"
                  fill="#70435A"
                />
              </svg>
            </span>
          </div>
        </div>
        <div
          className="col-12 my-2"
          role={"button"}
          onClick={() => {
            if (
              !ssn ||
              !dateOfBirth ||
              !annualIncome ||
              !isCustomerAgreedToLoanUnlockTerms
            ) {
              setErrors((prevState) => ({
                ...prevState,
                annualIncome: !annualIncome
                  ? "Required"
                  : prevState.annualIncome,
                termsCheckbox: !isCustomerAgreedToLoanUnlockTerms
                  ? "To unlock check the checkbox below"
                  : null,
              }));
            }
          }}
        >
          <button
            type="submit"
            className="btn btn-primary w-100 font-weight-600"
            disabled={
              !annualIncome ||
              !ssn ||
              !dateOfBirth ||
              !annualIncome ||
              !isCustomerAgreedToLoanUnlockTerms ||
              !!errors.dateOfBirth ||
              !!errors.ssn ||
              !!errors.termsCheckbox
            }
            onClick={(e) => {
              e.preventDefault();
              submitLoanUnlockingRequest();
            }}
          >
            Submit
          </button>
          {(errors.termsCheckbox || errors.global) && (
            <div className="col-12 mt-2 text-center">
              <div className="pl-1 text-primary text-small">
                {errors.termsCheckbox}
                {errors.global && (
                  <>
                    {errors.termsCheckbox && <br />}
                    {errors.global}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
      {submitForPrequalDisclosure && (
        <div className="pb-2 form-group text-start">
          <div className="form-check">
            <input
              className="form-check-input data-hj-allow"
              type="checkbox"
              id="agree-to-terms"
              autoComplete="off"
              checked={isTermsChecked}
              onChange={() => setIsTermsChecked(!isTermsChecked)}
            />
            <label
              className="form-check-label pl-1 text-small"
              htmlFor="agree-to-terms"
            >
              {submitForPrequalDisclosure}
            </label>
          </div>
          <div
            className="mt-2 text-small btn-link"
            role="button"
            onClick={() => {
              setEConsentDisclosureModalOpen(true);
            }}
          >
            {eSignSubmitDisclosure}
          </div>
        </div>
      )}
      {phoneNumberSolarDisclosure && (
        <>
          <hr className="mt-0 mb-3" />
          <div className="text-small mb-2">
            <span className="mr-1">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#FDF1F7" />
                <path
                  d="M9.23107 5.62145L8.91817 4.61362L6.50363 5.44276V2.76953H5.49697V5.44276L3.08243 4.61362L2.76953 5.62145L5.18407 6.45059L3.69454 8.60918L4.51071 9.23107L6.00025 7.07248L7.48978 9.23107L8.30595 8.60918L6.81642 6.45059L9.23107 5.62145Z"
                  fill="#70435A"
                />
              </svg>
            </span>
            {phoneNumberSolarDisclosure}
          </div>
        </>
      )}
      {eConsentDisclosureModalOpen && (
        <div className="modal-backdrop-cover">
          <div
            role="button"
            className="modal d-block show"
            onClick={() => setEConsentDisclosureModalOpen(false)}
          >
            <div
              role="button"
              className={`cursor-arrow modal-dialog modal-lg modal-dialog-centered h-auto m-auto`}
              style={{ width: "auto", maxWidth: "800px" }}
              onClick={(evt) => evt.stopPropagation()}
            >
              <div className="modal-content position-relative">
                <div className="modal-header p-0 pl-2 border-0 d-block">
                  <div
                    role="button"
                    className="float-right py-1 px-2 modal-header-button"
                    onClick={() => setEConsentDisclosureModalOpen(false)}
                  >
                    <img src={closeIcon} alt="close" />
                  </div>
                </div>
                <div
                  className="modal-body p-2 text-medium"
                  dangerouslySetInnerHTML={{ __html: eConsentDisclosure }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ItcPaydownTooltip = (props: {
  term: number;
  children: ReactNode;
}) => {
  const { term, children } = props;
  const { width } = useWindowDimensions();
  const calculatedLoanDetails = useRecoilValue(calculatedLoanDetailsAtom);

  return calculatedLoanDetails ? (
    <Tooltip
      html={
        <CustomTooltip className="p-2">
          <LoanPaydownDisclaimer
            term={term}
            calculatedLoanDetails={calculatedLoanDetails}
          />
        </CustomTooltip>
      }
      size="small"
      position="bottom"
      trigger={width >= WindowBreakPoints.md ? "mouseenter" : "click"}
      animation="shift"
      transitionFlip={false}
    >
      {children}
    </Tooltip>
  ) : (
    <></>
  );
};

export const LoanPaydownDisclaimer = (props: {
  term: number;
  calculatedLoanDetails: LoanDetails;
}) => {
  const { term, calculatedLoanDetails } = props;

  const {
    apr = 0,
    escalatedMonthlyPayment = 0,
    loanAmount = 0,
    monthlyPayment = 0,
    paydownPercentage = 0,
  } = calculatedLoanDetails || {};

  return (
    <>
      No down payment required. The fixed Annual Percentage Rate for a {term}{" "}
      year loan is {apr}%. The amount of your first 17 payments will be $
      {monthlyPayment.toLocaleString("en", {
        useGrouping: true,
      })}
      . If you make a voluntary payment of $
      {((paydownPercentage / 100) * loanAmount).toLocaleString("en", {
        useGrouping: true,
      })}{" "}
      ({paydownPercentage}% of your Amount Financed) before your 17th payment,
      your remaining {term * 12 - 18} payments will remain approximately $
      {monthlyPayment.toLocaleString("en", {
        useGrouping: true,
      })}
      . If no voluntary payment is made, the remaining {term * 12 - 18} payments
      will be $
      {escalatedMonthlyPayment.toLocaleString("en", {
        useGrouping: true,
      })}
      . The total financed amount is $
      {loanAmount.toLocaleString("en", {
        maximumFractionDigits: 0,
        useGrouping: true,
      })}{" "}
      for this loan.
    </>
  );
};

export default Step2;
