const prodConfig = {
  apiKey: "AIzaSyBM3o52h5dQLdMYVAI_GdeGLRE5Ki3L3AM",
  authDomain: "posf-fe773.firebaseapp.com",
  projectId: "posf-fe773",
  storageBucket: "posf-fe773.appspot.com",
  messagingSenderId: "529187572892",
  appId: "1:529187572892:web:0154ed2bee2b0b956ef159",
  measurementId: "G-2ZE59C7Z20",
};

const stagingConfig = {
  apiKey: "AIzaSyCRO4h4fJgL6uVM2LQLQjOEeYyEd_zsTnI",
  authDomain: "posf-test.firebaseapp.com",
  projectId: "posf-test",
  storageBucket: "posf-test.appspot.com",
  messagingSenderId: "518954640251",
  appId: "1:518954640251:web:6b9f36cdfeb68b50a013de",
  measurementId: "G-4PZWJX7BS0",
};

const demoConfig = {
  apiKey: "AIzaSyBudtpnnDa84uTPGIQxlqllfsm2Sblphvc",
  authDomain: "posf-demo.firebaseapp.com",
  projectId: "posf-demo",
  storageBucket: "posf-demo.appspot.com",
  messagingSenderId: "823140616957",
  appId: "1:823140616957:web:ff81760c72556c97d68c94",
  measurementId: "G-VG3M1XTR8D",
};

let config: {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

switch (process.env.REACT_APP_FIREBASE_ENV) {
  case "PRODUCTION":
    config = prodConfig;
    break;
  case "STAGING":
    config = stagingConfig;
    break;
  case "DEMO":
    config = demoConfig;
    break;
  default:
    config = stagingConfig;
    break;
}

export default config;
