import { atom } from "recoil";
import { DealPlans } from "../../types/deal";

// ----------------------------------------------------------------
// Step 1
// ----------------------------------------------------------------

export const step1DownpaymentPercentAtom = atom<number>({
  key: "step1DownpaymentPercentAtom",
  default: 0,
});

export const step1DownpaymentAtom = atom<number>({
  key: "step1DownpaymentAtom",
  default: 0,
});

export const step1SelectedPlanAtom = atom<DealPlans | null>({
  key: "step1SelectedPlanAtom",
  default: null,
});

export const step1IncludeBatteryAtom = atom<boolean>({
  key: "step1IncludeBatteryAtom",
  default: false,
});

// ----------------------------------------------------------------
// Step 2
// ----------------------------------------------------------------

export const addressInputAtom = atom<string | null>({
  key: "addressInputAtom",
  default: null,
});

export const addressLatAtom = atom<number | null>({
  key: "addressLatAtom",
  default: null,
});

export const addressLngAtom = atom<number | null>({
  key: "addressLngAtom",
  default: null,
});

export const customerDetailsFirstNameAtom = atom<string | null>({
  key: "customerDetailsFirstNameAtom",
  default: null,
});

export const customerDetailsLastNameAtom = atom<string | null>({
  key: "customerDetailsLastNameAtom",
  default: null,
});

export const customerDetailsEmailAtom = atom<string | null>({
  key: "customerDetailsEmailAtom",
  default: null,
});

export const customerDetailsPhoneNumberAtom = atom<string | null>({
  key: "customerDetailsPhoneNumberAtom",
  default: null,
});

export const customerDetailsAddressAtom = atom<string | null>({
  key: "customerDetailsAddressAtom",
  default: null,
});

export const customerDetailsCityAtom = atom<string | null>({
  key: "customerDetailsCityAtom",
  default: null,
});

export const customerDetailsStateAtom = atom<string | null>({
  key: "customerDetailsStateAtom",
  default: null,
});

export const customerDetailsZipAtom = atom<string | null>({
  key: "customerDetailsZipAtom",
  default: null,
});

export const customerDetailsDateOfBirthAtom = atom<string | null>({
  key: "customerDetailsDateOfBirthAtom",
  default: null,
});

export const customerDetailsSsnAtom = atom<string | null>({
  key: "customerDetailsSsnAtom",
  default: null,
});

export const isCustomerAgreedToLoanUnlockTermsAtom = atom<boolean>({
  key: "isCustomerAgreedToLoanUnlockTermsAtom",
  default: false,
});

export const isLoanUnlockedAtom = atom<boolean>({
  key: "isLoanUnlockedAtom",
  default: false,
});

// ----------------------------------------------------------------
// Step 3
// ----------------------------------------------------------------
