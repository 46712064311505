import React, { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Step } from "../../types/steps";
import stepChecked from "../../assets/images/step-checked.svg";
import ellipse from "../../assets/images/ellipse.svg";
import useWindowDimensions from "../../hooks";
import { WindowBreakPoints } from "../../types/ui";
import { StepperStatusSteps } from "../../types/ui";
import { contextProposalStepperSteps } from "../../state/atoms/ui";

const StepperStatus: FunctionComponent<{}> = () => {
  const params: { stepId: string } = useParams();
  const stepId = parseInt(params.stepId, 10);
  const { width } = useWindowDimensions();
  const [stepperStatus, setStepperStatus] = useRecoilState(
    contextProposalStepperSteps
  );

  const isMobile = width <= WindowBreakPoints.sm;
  useEffect(() => {
    calcStepsStatus(stepperStatus, stepId);
  }, [stepId]);

  const calcStepsStatus = (steps: Step[], stepId: Number) => {
    const newSteps = steps.map((step, idx) => {
      if (stepId === idx + 1) {
        return {
          ...step,
          stepStatus: StepperStatusSteps.CURRENT,
        };
      } else if (stepId > idx + 1) {
        return {
          ...step,
          stepStatus: StepperStatusSteps.SUCCESS,
        };
      } else if (stepId < idx + 1) {
        return {
          ...step,
          stepStatus: StepperStatusSteps.NEUTRAL,
        };
      }
      return { ...step };
    });
    setStepperStatus(newSteps);
  };

  const getStepStatusSvg = (stepStatus?: StepperStatusSteps) =>
    stepStatus === StepperStatusSteps.SUCCESS ? stepChecked : ellipse;

  return (
    <div className="max-w-800px mx-auto px-3 px-lg-0">
      <div className="d-flex">
        {stepperStatus?.map(({ title, stepStatus }, idx) => {
          return (
            <div
              key={title}
              className={`${
                idx !== stepperStatus.length - 1 ? "flex-grow-1" : ""
              } mt-05 d-flex align-items-center`}
            >
              {(isMobile || stepStatus !== StepperStatusSteps.NEUTRAL) && (
                <div className="position-relative text-white mr-sm-05 mb-025 mr-md-1 d-flex">
                  <img
                    className="stepper-step-number-svg"
                    src={getStepStatusSvg(stepStatus)}
                    alt="step-number"
                  />
                  {stepStatus !== StepperStatusSteps.SUCCESS && (
                    <p className="stepper-step-number position-absolute top-50 start-50 translate-middle font-weight-600 m-auto">
                      {idx + 1}
                    </p>
                  )}
                </div>
              )}
              {!isMobile && (
                <span
                  className={`stepper-step-title ${
                    stepStatus === StepperStatusSteps.NEUTRAL
                      ? "text-muted"
                      : "text-bold"
                  } mx-sm-025`}
                >
                  {title}
                </span>
              )}
              {idx !== stepperStatus.length - 1 && (
                <div className="flex-grow-1 mx-1 mx-lg-05 stepper-separator">
                  &nbsp;
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepperStatus;
