import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import CountUp from "react-countup";
import LoanSummary from "./LoanSummary";
import federalIncentiveIcon from "../../assets/images/pre-checkout/federal-incentive.svg";
import productionGuaranteeIcon from "../../assets/images/pre-checkout/production-guarantee.svg";
import monthlySavingsIcon from "../../assets/images/pre-checkout/monthly-savings.svg";
import systemCostIcon from "../../assets/images/pre-checkout/system-cost.svg";
import lifetimeSavingsIcon from "../../assets/images/pre-checkout/lifetime-savings.svg";
import treeSeedlingsIcon from "../../assets/images/pre-checkout/tree-seedlings.svg";
import co2Icon from "../../assets/images/pre-checkout/co2.svg";
import oilBarrelIcon from "../../assets/images/pre-checkout/oil-barrel.svg";
import equipmentWarrantyIcon from "../../assets/images/pre-checkout/equipment-warranty.svg";
import workmanshipWarrantyIcon from "../../assets/images/pre-checkout/workmanship-warranty.svg";
import tickIcon from "../../assets/images/pre-checkout/tick.svg";
import solarHouseIcon from "../../assets/images/pre-checkout/solar-house.svg";
import installerIcon from "../../assets/images/pre-checkout/installer.svg";
import locationIcon from "../../assets/images/pre-checkout/location.svg";
import { Tooltip } from "react-tippy";
import CustomTooltip from "../CustomTooltip";
import TooltipIcon from "../TooltipIcon";
import useWindowDimensions from "../../hooks";
import { WindowBreakPoints } from "../../types/ui";

import {
  contextProposalAtom,
  downpaymentCalculationsAtom,
} from "../../state/atoms/global";
import { step1SelectedPlanAtom } from "../../state/atoms/form";
import { DealPlans } from "../../types/deal";
import { Tag, TagType } from "../Tag";
import { getPlansTooltipMessagesSelector } from "../../state/selectors/form";

const Step3: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const plansTooltipMessages = useRecoilValue(getPlansTooltipMessagesSelector);
  const contextProposal = useRecoilValue(contextProposalAtom);
  const downpaymentCalculations = useRecoilValue(downpaymentCalculationsAtom);
  const selectedPlan = useRecoilValue(step1SelectedPlanAtom);

  const [systemCost, setSystemCost] = useState(0);
  const [monthlySavings, setMonthlySavings] = useState(0);
  const [lifetimeSavings, setLifetimeSavings] = useState(0);
  const [co2Emmisions, setCo2Emmisions] = useState(0);
  const [federalIncentive, setFederalIncentive] = useState(0);
  const [treeSeedlings, setTreeSeedlings] = useState(0);
  const [gallonsOfGasoline, setGallonsOfGasoline] = useState(0);
  const [systemSize, setSystemSize] = useState(0);
  const [installerName, setInstallerName] = useState("");
  const [installationAddress, setInstallationAddress] = useState("");
  const { width } = useWindowDimensions();

  useEffect(() => {
    const estProduction =
      contextProposal?.deal?.systemDetails?.estProduction || 0;
    setSystemCost(downpaymentCalculations[0].calculatedPlans.payNow);
    setMonthlySavings(
      downpaymentCalculations[0].savingDetails.payNow.lifeTimeSavings / 25 / 12
    );
    setLifetimeSavings(
      downpaymentCalculations[0].savingDetails.payNow.lifeTimeSavings
    );
    setFederalIncentive(
      downpaymentCalculations[0].calculatedPlans.payNow * 0.3
    );

    const co2calc = (25 * estProduction) / 986;

    setCo2Emmisions(co2calc);
    setTreeSeedlings(co2calc / 0.06);
    setGallonsOfGasoline(25 * estProduction * 0.029678483099753);

    if (
      contextProposal?.deal?.systemLocation &&
      contextProposal?.deal?.systemDetails
    ) {
      setInstallerName(contextProposal.installer.name || "");

      const { systemLocation, systemDetails } = contextProposal.deal;
      setInstallationAddress(
        `${systemLocation.address}, ${systemLocation.city} ${systemLocation.state}, ${systemLocation?.zip}`
      );

      setSystemSize(systemDetails.systemSize);
    }
  }, [contextProposal, downpaymentCalculations]);

  return selectedPlan === DealPlans.LOAN12 ||
    selectedPlan === DealPlans.LOAN15 ||
    selectedPlan === DealPlans.LOAN20 ||
    selectedPlan === DealPlans.LOAN25 ? (
    <LoanSummary />
  ) : (
    <div className="max-w-1100px mx-auto px-4 px-xl-0 h-100 w-100 d-flex flex-column justify-content-center">
      <h3 className="font-weight-600 px-md-4 px-xl-0 mt-2 mb-0 text-left">
        Summary Details
      </h3>
      <div className="d-flex px-md-4 px-xl-0 justify-content-between">
        <span className="mt-1 mb-0 text-muted text-left">
          Before moving forward, here&apos;s what you need to know
        </span>
        <span className="d-none d-sm-flex align-items-end">
          {selectedPlan === DealPlans.PAY_AS_YOU_GO && (
            <Tag tagType={TagType.lease}>LEASE</Tag>
          )}
          {selectedPlan === DealPlans.PAY_NOW && (
            <Tag tagType={TagType.buy}>BUY</Tag>
          )}
        </span>
      </div>
      <div className="max-w-1100px mx-auto px-md-4 px-xl-0 h-100 w-100 d-flex flex-column justify-content-center">
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-lg-6">
            <div className="card h-100 rounded">
              <div className="pre-checkout-card-title">Costs &amp; Savings</div>
              <div className="divider" />
              <div className="px-2 px-lg-4 py-2">
                <div className="row justify-content-between">
                  <div className="col-12 col-sm-5 text-center mb-2">
                    <div className="d-flex align-items-end mr-2">
                      <img
                        className="pre-checkout-card-img"
                        src={monthlySavingsIcon}
                        alt="monthly-savings"
                      />
                      <div className="text-left ml-2">
                        $
                        <span className="pre-checkout-card-number">
                          <CountUp
                            end={monthlySavings}
                            duration={1}
                            separator=","
                            decimal="."
                          />
                        </span>
                        <div className="text-medium">Monthly Savings</div>
                      </div>
                    </div>
                  </div>
                  {selectedPlan !== DealPlans.PAY_AS_YOU_GO &&
                    selectedPlan !== DealPlans.PAY_NOW && (
                      <div className="col-12 col-sm-7 mb-2">
                        <div className="d-flex align-items-end mr-2">
                          <img
                            className="pre-checkout-card-img"
                            src={federalIncentiveIcon}
                            alt="federal-incentive-icon"
                          />
                          <div className="text-left ml-2">
                            $
                            <span className="pre-checkout-card-number">
                              <CountUp
                                end={federalIncentive}
                                duration={1}
                                separator=","
                                decimal="."
                              />
                            </span>
                            <div className="text-medium">Federal Incentive</div>
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="col-12 col-sm-5 mb-2">
                    <div className="d-flex align-items-end mr-2">
                      <img
                        className="pre-checkout-card-img"
                        src={systemCostIcon}
                        alt="system-cost-icon"
                      />
                      <div className="text-left ml-2">
                        $
                        <span className="pre-checkout-card-number">
                          <CountUp
                            end={systemCost}
                            duration={1}
                            separator=","
                            decimal="."
                          />
                        </span>
                        <div className="text-medium">System Cost</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-7 mb-2">
                    <div className="d-flex align-items-end mr-2">
                      <img
                        className="pre-checkout-card-img"
                        src={lifetimeSavingsIcon}
                        alt="monthly-savings"
                      />

                      <div className="text-left ml-2">
                        $
                        <span className="pre-checkout-card-number">
                          <CountUp
                            end={lifetimeSavings}
                            duration={1}
                            separator=","
                            decimal="."
                          />
                        </span>
                        <div className="text-medium">
                          <span>Est. Lifetime Savings</span>
                          {(selectedPlan === DealPlans.PAY_NOW ||
                            selectedPlan === DealPlans.PAY_AS_YOU_GO) && (
                            <Tooltip
                              html={
                                <CustomTooltip className="p-2 m-05">
                                  {selectedPlan === DealPlans.PAY_NOW &&
                                    plansTooltipMessages.buy}

                                  {selectedPlan === DealPlans.PAY_AS_YOU_GO &&
                                    plansTooltipMessages.lease}
                                </CustomTooltip>
                              }
                              size="small"
                              trigger={
                                width >= WindowBreakPoints.md
                                  ? "mouseenter"
                                  : "click"
                              }
                              animation="shift"
                              transitionFlip={false}
                            >
                              <span className="ml-05 line-height-1" style={{}}>
                                <TooltipIcon />
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-2 mt-lg-0">
            <div className="card h-100 rounded">
              <div className="pre-checkout-card-title">
                Environmental Impact
              </div>
              <div className="divider" />
              <div className="row px-4 py-3">
                <div className="col text-center">
                  <img
                    className="pre-checkout-card-img"
                    src={treeSeedlingsIcon}
                    alt="tree-seedlings"
                  />
                  <div>
                    <span className="pre-checkout-card-number">
                      <CountUp
                        end={treeSeedlings}
                        duration={1}
                        separator=","
                        decimal="."
                      />
                    </span>
                  </div>
                  <div className="text-center text-medium">
                    Tree Seedlings
                    <br />
                    Grown in 10 years
                  </div>
                </div>
                <div className="col text-center">
                  <img
                    className="pre-checkout-card-img"
                    src={co2Icon}
                    alt="tree-seedlings"
                  />
                  <div>
                    <span className="pre-checkout-card-number">
                      <CountUp
                        end={co2Emmisions}
                        duration={1}
                        separator=","
                        decimal="."
                      />
                    </span>
                  </div>
                  <div className="text-center text-medium">
                    Tons of CO2
                    <br />
                    Emissions Avoided
                  </div>
                </div>
                <div className="col text-center">
                  <img
                    className="pre-checkout-card-img"
                    src={oilBarrelIcon}
                    alt="oil-barrel"
                  />
                  <div>
                    <span className="pre-checkout-card-number">
                      <CountUp
                        end={gallonsOfGasoline}
                        duration={1}
                        separator=","
                        decimal="."
                      />
                    </span>
                  </div>
                  <div className="text-center text-medium">
                    Galons of Gasoline
                    <br />
                    Consumed
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <div className="card h-100 rounded">
              <div className="pre-checkout-card-title">Service</div>
              <div className="divider" />
              <div className="d-flex h-100 flex-column justify-content-center">
                <div className="row py-2 px-4">
                  <div className="col text-center">
                    <img
                      className="pre-checkout-card-img"
                      src={equipmentWarrantyIcon}
                      alt="tree-seedlings"
                    />
                    <div className="text-center text-medium py-1">
                      Equipment
                      <br />
                      Warranty
                    </div>
                  </div>
                  <div className="col text-center">
                    <img
                      className="pre-checkout-card-img"
                      src={workmanshipWarrantyIcon}
                      alt="workmanship-warranty-icon"
                    />
                    <div className="text-center text-medium py-1">
                      Workmanship
                      <br />
                      Warranty
                    </div>
                  </div>
                  {selectedPlan !== DealPlans.PAY_NOW && (
                    <div className="col text-center">
                      <img
                        className="pre-checkout-card-img"
                        src={productionGuaranteeIcon}
                        alt="workmanship-warranty-icon"
                      />
                      <div className="text-center text-medium py-1">
                        Production
                        <br />
                        Guarantee
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="divider" />
              <div className="row py-1 px-4">
                <div className="col text-center">
                  <img src={tickIcon} alt="tick-icon" />
                </div>
                <div className="col text-center">
                  <img src={tickIcon} alt="tick-icon" />
                </div>
                {selectedPlan !== DealPlans.PAY_NOW && (
                  <div className="col text-center">
                    <img src={tickIcon} alt="tick-icon" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <div className="card h-100 rounded">
              <div className="pre-checkout-card-title">
                Installation Details
              </div>
              <div className="divider" />
              <div className="d-flex m-auto px-4 py-2">
                <div className="mr-4">
                  <img
                    className="pre-checkout-card-img"
                    src={solarHouseIcon}
                    alt="solar-house"
                  />
                  <div className="text-left pt-1">
                    <span className="text-bold">{systemSize}</span>kW
                  </div>
                  <div className="text-medium">System size</div>
                </div>
                <div className="ml-4">
                  <img
                    className="pre-checkout-card-img"
                    src={installerIcon}
                    alt="solar-house"
                  />
                  <div className="text-left pt-1">
                    <span className="text-bold">Installer</span>
                  </div>
                  <div className="text-medium">{installerName}</div>
                </div>
              </div>
              <div className="d-flex px-4 py-2 justify-content-start">
                <img src={locationIcon} alt="location-icon" />
                <div className="d-flex flex-column justify-content-start text-left px-2">
                  <div className="text-bold text-medium">Install address</div>
                  <div className="text-medium">{installationAddress}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right mt-4 mb-8">
            <button
              type="submit"
              className="btn btn-primary w-100 w-lg-25 font-weight-600"
              onClick={() => {
                if (selectedPlan === DealPlans.PAY_NOW) {
                  history.push(`/step-4${search}`);
                } else if (selectedPlan === DealPlans.PAY_AS_YOU_GO) {
                  history.push(`/success${search}`);
                }
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
