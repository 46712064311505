import React, { FunctionComponent, useEffect, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { signedInUserAtom } from "../../state/atoms/auth";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const Steps: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const params: { stepId: string } = useParams();
  const stepId = parseInt(params.stepId, 10);
  const signedInUser = useRecoilValue(signedInUserAtom);

  const [animation, setAnimation] = useState("animate__slideInRight");
  const [currentStep, setCurrentStep] = useState(stepId);

  useEffect(() => {
    if (stepId > currentStep) {
      setAnimation("animate__slideOutLeft");
      setTimeout(() => {
        setAnimation("animate__slideInRight");
        setCurrentStep(stepId);
      }, 250);
    } else if (stepId < currentStep) {
      setAnimation("animate__slideOutRight");
      setTimeout(() => {
        setAnimation("animate__slideInLeft");
        setCurrentStep(stepId);
      }, 250);
    }
  }, [stepId, currentStep]);

  if (!signedInUser && currentStep > 1) {
    return <Redirect to={`/go-solar${search}`} />;
  }

  return (
    <div className={`text-center animate__animated ${animation}`}>
      {currentStep === 1 && <Step1 />}
      {currentStep === 2 && <Step2 />}
      {currentStep === 3 && <Step3 />}
      {currentStep === 4 && <Step4 />}
    </div>
  );
};

export default Steps;
