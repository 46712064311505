export interface LocationDetails {
  address: string;
  city: string;
  state: string;
  zip: string;
}

export interface SystemDetails {
  systemSize: number;
  estProduction: number;
  utilityRate: number;
  utilityProvider: string;
}

export interface BatteryDetails {
  totalCost: number;
  totalCapacity: number;
}

export enum DealPlans {
  "PAY_AS_YOU_GO" = "PAY_AS_YOU_GO",
  "FIXED" = "FIXED",
  "PAY_NOW" = "PAY_NOW",
  "LOCK_RATE" = "LOCK_RATE",
  "YEARLY" = "YEARLY",
  "LOAN12" = "LOAN12",
  "LOAN15" = "LOAN15",
  "LOAN20" = "LOAN20",
  "LOAN25" = "LOAN25",
}

export interface Deal {
  systemLocation?: LocationDetails;
  systemDetails?: SystemDetails;
  hasAdditionalBattery?: boolean;
  batteryDetails?: BatteryDetails;
}
