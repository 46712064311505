import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useRecoilValue } from "recoil";
import {
  canSubmitStep1Selector,
  canSubmitStep2Selector,
} from "../../state/selectors/form";
import StepperStatus from "./StepperStatus";

const Stepper: FunctionComponent<{}> = () => {
  const { pathname, search } = useLocation();
  const params: { stepId: string } = useParams();
  const history = useHistory();

  const stepId = parseInt(params.stepId, 10);

  const canSubmitStep1 = useRecoilValue(canSubmitStep1Selector);
  const canSubmitStep2 = useRecoilValue(canSubmitStep2Selector);

  const [currentStep, setCurrentStep] = useState(stepId);

  useEffect(() => {
    if (pathname.startsWith("/step-") && pathname !== `/step-${currentStep}`) {
      history.push(`/step-1${search}`);
    }

    if (
      (currentStep > 1 && !canSubmitStep1) ||
      (currentStep > 2 && !canSubmitStep2)
    ) {
      history.push(`/step-1${search}`);
    }
  }, [currentStep]);

  return (
    <div className="container-lg d-flex my-4 p-0">
      <div className="d-none d-lg-block w-100px text-start">
        <button
          className={`stepper-element ${
            stepId === 1 ? "opacity-50 cursor-arrow" : ""
          } btn btn-outline-secondary py-025`}
          onClick={() => {
            const stepIdToNavigateTo = stepId - 1 || 1;
            setCurrentStep(stepIdToNavigateTo);
            history.push(`/step-${stepIdToNavigateTo}${search}`);
          }}
        >
          <span className="text-uppercase">
            <span className="mr-05" style={{ marginTop: "-2px" }}>
              <svg
                width="7"
                height="8"
                viewBox="0 0 7 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.603895 3.92009C0.335497 3.76513 0.335498 3.37773 0.603896 3.22277L6.03896 0.0848324C6.30736 -0.0701268 6.64286 0.123573 6.64286 0.433492L6.64286 6.70937C6.64286 7.01928 6.30736 7.21298 6.03896 7.05802L0.603895 3.92009Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            Back
          </span>
        </button>
      </div>
      <div className="flex-grow-1">
        <StepperStatus />
      </div>
      <div className="d-none d-lg-block w-100px" />
    </div>
  );
};

export default Stepper;
