import React, { FunctionComponent } from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";

export interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  isContained?: boolean;
}

export const Loading: FunctionComponent<LoadingProps> = ({
  isContained,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`d-flex align-items-center justify-content-center ${
        className ? className : ""
      } ${isContained ? "loading-contained" : "loading-fullscreen"}`}
    >
      <div>
        <div className="text-medium">
          <Lottie
            style={{ width: "5rem" }}
            animationData={loadingAnimation}
            loop
          />
        </div>
      </div>
    </div>
  );
};
