import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import CountUp from "react-countup";
import { isInDemoMode } from "../../state/atoms/ui";
import loader from "../../assets/images/loader-white.gif";

import {
  customerDetailsEmailAtom,
  customerDetailsFirstNameAtom,
  customerDetailsLastNameAtom,
} from "../../state/atoms/form";
import {
  contextITCRateAtom,
  contextProposalAtom,
  downpaymentCalculationsAtom,
  stripeSetupIntentAtom,
} from "../../state/atoms/global";

import { Tag, TagType } from "../Tag";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const demoMode = useRecoilValue(isInDemoMode);
  const customerDetailsFirstName = useRecoilValue(customerDetailsFirstNameAtom);
  const customerDetailsLastName = useRecoilValue(customerDetailsLastNameAtom);
  const customerDetailsEmail = useRecoilValue(customerDetailsEmailAtom);
  const setStripeSetupIntent = useSetRecoilState(stripeSetupIntentAtom);
  const ITCRate = useRecoilValue(contextITCRateAtom);

  const { search } = useLocation();

  const contextProposal = useRecoilValue(contextProposalAtom);
  const downpaymentCalculations = useRecoilValue(downpaymentCalculationsAtom);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [systemCost, setSystemCost] = useState(0);
  const [lifetimeSavings, setLifetimeSavings] = useState(0);
  const [federalIncentive, setFederalIncentive] = useState(0);
  const [systemSize, setSystemSize] = useState(0);
  const [installerName, setInstallerName] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const { error } =
          (await stripe
            .confirmSetup({
              elements,
              redirect: "if_required",
            })
            .then(({ error, setupIntent }) => {
              if (error) {
                return { error };
              } else {
                setStripeSetupIntent(setupIntent);
                history.push(`/success${search}`);
              }
            })) || {};

        if (error) {
          console.error("Error applying");
        }
      } catch (err) {
        console.error(`Error: ${JSON.stringify(err)}`);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    setSystemCost(downpaymentCalculations[0].calculatedPlans.payNow);
    setLifetimeSavings(
      downpaymentCalculations[0].savingDetails.payNow.lifeTimeSavings
    );

    setFederalIncentive(
      downpaymentCalculations[0].calculatedPlans.payNow * ITCRate
    );

    if (
      contextProposal?.deal?.systemLocation &&
      contextProposal?.deal?.systemDetails
    ) {
      const { systemDetails } = contextProposal.deal;
      setInstallerName(contextProposal.installer.name || "");
      setSystemSize(systemDetails.systemSize);
    }
  }, [contextProposal, downpaymentCalculations]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mx-auto px-3 px-md-0 mt-8 max-w-1100px">
        <div className="row">
          <div className="col-12 col-lg-6 text-start">
            <h5 className="mb-2">
              Let&apos;s connect.
              <br />
              Your account won’t be charged.
            </h5>
            <div className="text-medium text-muted mb-2">
              You will only be charged once you sign the contract, according to
              agreed milestones.
            </div>
            <div className="card rounded">
              <div className="card-body pb-2 pt-3 px-2">
                <div className="d-flex px-2">
                  <h5 className="text-bold mb-0">Payment Summary</h5>
                  <div className="ms-auto">
                    <Tag tagType={TagType.buy} width="unset">
                      MILESTONES PAYMENTS
                    </Tag>
                  </div>
                </div>
                <div className="d-flex mt-2 px-2">
                  <div className="mr-4">
                    <div className="text-medium text-muted mb-05">
                      System Size
                    </div>
                    <div>
                      <span className="text-medium">
                        <h5 className="d-inline">{systemSize}</h5>
                        kW
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-medium text-muted mb-05">
                      Installer
                    </div>
                    <div>
                      <span className="text-medium">
                        <h5 className="d-inline">{installerName}</h5>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-2 mx-2 py-1 border-bottom">
                  <div>System Cost</div>
                  <div className="ms-auto">
                    <span className="text-medium">
                      $
                      <h5 className="d-inline">
                        <CountUp
                          end={systemCost}
                          duration={1}
                          separator=","
                          decimal="."
                        />
                      </h5>
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-2 mx-2 py-1">
                  <div>Federal Incentive</div>
                  <div className="ms-auto">
                    <span className="text-medium">
                      -$
                      <h5 className="d-inline">
                        <CountUp
                          end={federalIncentive}
                          duration={1}
                          separator=","
                          decimal="."
                        />
                      </h5>
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-2 px-2 pt-2 pb-1 rounded bg-muted">
                  <div>Price after Potential Incentives</div>
                  <div className="ms-auto">
                    <span>
                      $
                      <h4 className="d-inline text-bold">
                        <CountUp
                          end={systemCost - federalIncentive}
                          duration={1}
                          separator=","
                          decimal="."
                        />
                      </h4>
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-3 px-2">
                  <span className="text-medium text-muted">
                    Est. lifetime savings $
                    <CountUp
                      end={lifetimeSavings}
                      duration={1}
                      separator=","
                      decimal="."
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4 mt-lg-0">
            <div
              className="card rounded h-100"
              style={{ backgroundColor: "#f8fafc" }}
            >
              <div className="card-body py-4">
                {demoMode ? (
                  <span>I am a demo</span>
                ) : (
                  <PaymentElement
                    options={{
                      defaultValues: {
                        billingDetails: {
                          email: `${customerDetailsEmail}`,
                          name: `${customerDetailsFirstName} ${customerDetailsLastName}`,
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="text-right mt-4 mb-8">
          <button
            type="submit"
            className="btn btn-primary w-100 w-lg-25 font-weight-600"
            disabled={isSubmitting || demoMode}
          >
            {isSubmitting ? (
              <img height="10px" src={loader} alt="Submitting..." />
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
