import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import CountUp from "react-countup";
import { Tooltip } from "react-tippy";

import { Tag, TagType } from "../Tag";

import monthlySavingsIcon from "../../assets/images/pre-checkout/monthly-savings.svg";
import lifetimeSavingsIcon from "../../assets/images/pre-checkout/lifetime-savings.svg";
import treeSeedlingsIcon from "../../assets/images/pre-checkout/tree-seedlings.svg";
import co2Icon from "../../assets/images/pre-checkout/co2.svg";
import oilBarrelIcon from "../../assets/images/pre-checkout/oil-barrel.svg";
import equipmentWarrantyIcon from "../../assets/images/pre-checkout/equipment-warranty.svg";
import workmanshipWarrantyIcon from "../../assets/images/pre-checkout/workmanship-warranty.svg";
import tickIcon from "../../assets/images/pre-checkout/tick.svg";
import solarHouseIcon from "../../assets/images/pre-checkout/solar-house.svg";
import installerIcon from "../../assets/images/pre-checkout/installer.svg";
import locationIcon from "../../assets/images/pre-checkout/location.svg";
import sustainableEnergyIcon from "../../assets/images/pre-checkout/sustainable-energy-2.svg";
import planSafeAndSound from "../../assets/images/plans/plan-v2-safe-and-sound.svg";
import loanTermIcon from "../../assets/images/pre-checkout/loan-term.svg";
import sunlightFinancialIcon from "../../assets/images/pre-checkout/sunlight-financial.svg";
import downpaymentIcon from "../../assets/images/pre-checkout/downpayment.svg";
import moneyBagIcon from "../../assets/images/pre-checkout/money-bag.svg";
import { isInDemoMode } from "../../state/atoms/ui";

import {
  calculatedLoanDetailsAtom,
  contextITCRateAtom,
  contextProposalAtom,
  contextStateIncentiveAtom,
  downpaymentCalculationsAtom,
  isItcOnAtom,
} from "../../state/atoms/global";
import {
  step1DownpaymentAtom,
  step1SelectedPlanAtom,
} from "../../state/atoms/form";
import { WindowBreakPoints } from "../../types/ui";
import useWindowDimensions from "../../hooks";
import { ItcPaydownTooltip } from "./Step2";
import { DealPlans } from "../../types/deal";
import { getNumberAsLocalString } from "../../services/utils";
import CustomTooltip from "../CustomTooltip";
import TooltipIcon from "../TooltipIcon";
import { getPlansTooltipMessagesSelector } from "../../state/selectors/form";

const getSelectedTerm = (selectedPlan: DealPlans): number => {
  switch (selectedPlan) {
    case DealPlans.LOAN12:
      return 12;
    case DealPlans.LOAN15:
      return 15;
    case DealPlans.LOAN20:
      return 20;
    default:
      return 25;
  }
};

const LoanSummary: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();
  const { width } = useWindowDimensions();

  const contextProposal = useRecoilValue(contextProposalAtom);
  const ITCRate = useRecoilValue(contextITCRateAtom);
  const stateIncentive = useRecoilValue(contextStateIncentiveAtom);
  const downpaymentCalculations = useRecoilValue(downpaymentCalculationsAtom);
  const downpayment = useRecoilValue(step1DownpaymentAtom);
  const selectedPlan = useRecoilValue(step1SelectedPlanAtom);
  const calculatedLoanDetails = useRecoilValue(calculatedLoanDetailsAtom);
  const [isItcOn, setIsItcOn] = useRecoilState(isItcOnAtom);
  const demoMode = useRecoilValue(isInDemoMode);

  const selectedTerm = getSelectedTerm(selectedPlan || DealPlans.LOAN25);

  const {
    deal: { systemDetails },
  } = contextProposal || {
    deal: {},
  };

  const { utilityRate, estProduction } = systemDetails || {
    systemSize: 0,
    estProduction: 0,
    utilityRate: 0,
  };

  const {
    monthlyPayment,
    apr,
    escalatedMonthlyPayment,
    loanAmount,
    paydownPercentage,
    saving,
  } = calculatedLoanDetails || {
    monthlyPayment: 0,
    apr: 0,
    escalatedMonthlyPayment: 0,
    loanAmount: 0,
    paydownPercentage: 0,
    saving: {
      percentage: 0,
      lifeTimeSavings: 0,
    },
  };

  const estimatedITC = ITCRate * (loanAmount + downpayment);
  const estimatedITCString = getNumberAsLocalString(estimatedITC);
  const totalFinancedAmount = getNumberAsLocalString(loanAmount);

  const paydownAmountString = getNumberAsLocalString(
    !isItcOn ? 0 : (paydownPercentage * loanAmount) / 100
  );

  const [co2Emmisions, setCo2Emmisions] = useState(0);
  const [treeSeedlings, setTreeSeedlings] = useState(0);
  const [gallonsOfGasoline, setGallonsOfGasoline] = useState(0);
  const [systemSize, setSystemSize] = useState(0);
  const [installerName, setInstallerName] = useState("");
  const [installationAddress, setInstallationAddress] = useState("");
  const plansTooltipMessages = useRecoilValue(getPlansTooltipMessagesSelector);
  const downpaymentTip = "Paid directly to the installer according to terms";
  const lifetimeSavingsTip = plansTooltipMessages.loan;

  const footerInfo = `No down payment required. The fixed Annual Percentage Rate for a 15
  year loan is 40%. The amount of your first 17 payments will be $160.
  If you make a voluntary payment of $30,000 (40% of your Amount
  Financed) before your 17th payment, your remaining 15 years-18
  payments will remain approximately $160. If no voluntary payment is
  made, the remaining 15 years-18 payments will be $175.`;

  const showFirstYearSavingsMode = true;

  const firstYearMonthlySavings =
    (estProduction * (utilityRate / 100) -
      monthlyPayment +
      estimatedITC +
      stateIncentive) /
    25 /
    12;

  useEffect(() => {
    const estProduction =
      contextProposal?.deal?.systemDetails?.estProduction || 0;

    const co2calc = (25 * estProduction) / 986;

    setCo2Emmisions(co2calc);
    setTreeSeedlings(co2calc / 0.06);
    setGallonsOfGasoline(25 * estProduction * 0.029678483099753);

    if (
      contextProposal?.deal?.systemLocation &&
      contextProposal?.deal?.systemDetails
    ) {
      setInstallerName(contextProposal.installer.name || "");

      const { systemLocation, systemDetails } = contextProposal.deal;
      setInstallationAddress(
        `${systemLocation.address}, ${systemLocation.city} ${systemLocation.state}, ${systemLocation?.zip}`
      );

      setSystemSize(systemDetails.systemSize);
    }
  }, [contextProposal, downpaymentCalculations]);

  const splitedAddress = installationAddress.split(",");

  return (
    <div className="max-w-1100px mx-auto px-4 px-xl-0 h-100 w-100 d-flex flex-column justify-content-center">
      <h3 className="font-weight-600 mt-2 mb-0 text-left">Summary Details</h3>
      <div className="d-flex justify-content-between">
        <span className="mt-1 mb-0 text-muted text-left">
          Before moving forward, here&apos;s what you need to know
        </span>
        <span className="d-none d-sm-flex align-items-end">
          <Tag tagType={TagType.loan}>LOAN</Tag>
        </span>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="row">
          <div className="col-12 col-lg-6 mb-2 mb-lg-0 p-0">
            <div className="card h-100 rounded px-2">
              <div className="pre-checkout-card-title">Payment Details</div>
              <div className="divider" />
              <div className="row align-items-center justify-content-between px-2 pt-2">
                <div className="col-12 col-sm-6 mb-2">
                  <div className="d-flex align-items-end mr-2">
                    <img
                      className="pre-checkout-card-img w-25"
                      src={planSafeAndSound}
                      alt="safe-and-sound-plan"
                    />
                    <div className="text-left ml-2">
                      $
                      <span className="pre-checkout-card-number">
                        <CountUp
                          start={
                            isItcOn
                              ? monthlyPayment || 0
                              : escalatedMonthlyPayment || 0
                          }
                          end={
                            isItcOn
                              ? monthlyPayment || 0
                              : escalatedMonthlyPayment || 0
                          }
                          duration={0.75}
                          separator=","
                          decimal="."
                        />
                      </span>
                      <div className="text-medium">Monthly Rate</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 mb-2">
                  <div className="d-flex align-items-end mr-2 h-100">
                    <img
                      className="pre-checkout-card-img w-25"
                      src={downpaymentIcon}
                      alt="safe-and-sound-plan"
                    />
                    <div className="text-left ml-2 align-self-start">
                      $
                      <span className="pre-checkout-card-number">
                        <CountUp
                          start={0}
                          end={downpayment}
                          duration={0.75}
                          separator=","
                          decimal="."
                        />
                      </span>
                      <div>
                        <Tooltip
                          html={
                            <CustomTooltip className="p-2">
                              {downpaymentTip}
                            </CustomTooltip>
                          }
                          size="small"
                          position="bottom"
                          trigger={
                            width >= WindowBreakPoints.md
                              ? "mouseenter"
                              : "click"
                          }
                          animation="shift"
                          transitionFlip={false}
                        >
                          <div className="text-medium d-flex gap-1 align-items-center">
                            <span className="no-wrap">Downpayment</span>
                            <span
                              className="ml-05 line-height-1"
                              style={{ marginTop: "1px" }}
                            >
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="6"
                                  cy="6"
                                  r="6"
                                  fill="currentColor"
                                />
                                <path
                                  d="M6.00419 3.89877C5.71918 3.89877 5.48484 3.81431 5.30117 3.64537C5.12383 3.46994 5.03516 3.25552 5.03516 3.00211C5.03516 2.74221 5.12383 2.52779 5.30117 2.35885C5.48484 2.18342 5.71918 2.0957 6.00419 2.0957C6.28287 2.0957 6.51088 2.18342 6.68822 2.35885C6.87189 2.52779 6.96373 2.74221 6.96373 3.00211C6.96373 3.25552 6.87189 3.46994 6.68822 3.64537C6.51088 3.81431 6.28287 3.89877 6.00419 3.89877ZM6.81172 4.46406V9.90251H5.18716V4.46406H6.81172Z"
                                  fill="#ffffff"
                                />
                              </svg>
                            </span>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex px-2 py-2 flex-column">
                <div className="d-flex justify-content-between align-items-center py-2">
                  <span>Term</span>
                  <span>
                    <img
                      className="mx-05 h-100"
                      src={loanTermIcon}
                      alt="loan-term"
                    />
                    <span className="text-bold">{selectedTerm}</span>
                    <span className="ml-05">Years</span>
                  </span>
                </div>
                <div className="divider" />
                <div className="d-flex justify-content-between align-items-center py-2">
                  <span>APR</span>
                  <span className="text-bold">{apr}%</span>
                </div>
                <div className="divider" />
                <div className="d-flex justify-content-between align-items-center py-2">
                  <span>Estimated ITC</span>
                  <span>
                    <span className="text-medium">$</span>
                    <span className="text-bold">{estimatedITCString}</span>
                  </span>
                </div>
                <div className="divider" />
                <div className="d-flex justify-content-between align-items-center py-2">
                  <span>
                    <ItcPaydownTooltip term={selectedTerm}>
                      <div className="d-flex align-items-center gap-1">
                        <span className="no-wrap">ITC paydown</span>
                        <span
                          className="ml-025 line-height-1"
                          style={{ marginTop: "0px" }}
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="6" cy="6" r="6" fill="currentColor" />
                            <path
                              d="M6.00419 3.89877C5.71918 3.89877 5.48484 3.81431 5.30117 3.64537C5.12383 3.46994 5.03516 3.25552 5.03516 3.00211C5.03516 2.74221 5.12383 2.52779 5.30117 2.35885C5.48484 2.18342 5.71918 2.0957 6.00419 2.0957C6.28287 2.0957 6.51088 2.18342 6.68822 2.35885C6.87189 2.52779 6.96373 2.74221 6.96373 3.00211C6.96373 3.25552 6.87189 3.46994 6.68822 3.64537C6.51088 3.81431 6.28287 3.89877 6.00419 3.89877ZM6.81172 4.46406V9.90251H5.18716V4.46406H6.81172Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </span>
                      </div>
                    </ItcPaydownTooltip>
                  </span>
                  <span className="text-bold">
                    <input
                      className="toggle-checkbox"
                      id="itc-checkbox"
                      type="checkbox"
                      checked={isItcOn}
                      onChange={() => {
                        setIsItcOn(!isItcOn);
                      }}
                    />
                    <label
                      className="toggle-checkbox-label"
                      htmlFor="itc-checkbox"
                    >
                      <span className="toggle-checkbox-button" />
                    </label>
                  </span>
                </div>
                <div className="bg-muted slightly-rounded py-2 px-3 my-2">
                  <div className="d-flex justify-content-between align-items-center py-1">
                    <span className="col-8 text-start font-md text-truncate">
                      Paydown amount
                    </span>
                    <span className="text-bold">${paydownAmountString}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center py-1">
                    <span className="col-8 text-start font-md text-truncate">
                      Payments 1-17
                    </span>
                    <span className="col text-end">
                      <span className="text-bold">
                        ${getNumberAsLocalString(monthlyPayment)}
                      </span>
                      <span className="text-disabled text-medium"> /month</span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center py-1">
                    <span className="col-8 text-start font-md">
                      Payments 18+ <wbr />
                      <span>(with&nbsp;paydown)</span>
                    </span>
                    <span className="col text-end">
                      <span
                        className={`${
                          !isItcOn
                            ? "text-disabled text-decoration-line-through"
                            : ""
                        } text-bold`}
                      >
                        ${getNumberAsLocalString(monthlyPayment)}
                      </span>
                      <span className="text-disabled text-medium"> /month</span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center py-1">
                    <span className="col-8 text-start font-md mb-0">
                      Payments 18+ <wbr />
                      <span>(without&nbsp;paydown)</span>
                    </span>
                    <span className="col text-end mb-0">
                      <span
                        className={`${
                          isItcOn
                            ? "text-disabled text-decoration-line-through"
                            : ""
                        } text-bold`}
                      >
                        ${getNumberAsLocalString(escalatedMonthlyPayment)}
                      </span>
                      <span className="text-disabled text-medium"> /month</span>
                    </span>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center py-2">
                  <span className="text-start">First payment</span>
                  <span className="text-end text-bold">
                    60 days post-install
                  </span>
                </div>
                <div className="divider" />
                <div className="d-flex justify-content-between align-items-center py-2">
                  <span className="text-start">Total financed amount</span>
                  <span className="text-end text-bold">
                    ${totalFinancedAmount}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-between pr-0 pl-0 pl-lg-2">
            <div className="row w-100">
              <div className="card h-100 rounded p-0">
                <div className="pre-checkout-card-title px-2">Savings</div>
                <div className="divider" />
                <div className="ml-sm-2 pt-3 pb-1">
                  <div className="row justify-content-center">
                    <div className="col-12 col-sm-6 p-0 mb-2 d-flex justify-content-center">
                      <div className="d-flex align-items-sm-end justify-content-center flex-row">
                        <img
                          className="pre-checkout-card-img align-self-start mr-2"
                          src={
                            showFirstYearSavingsMode
                              ? lifetimeSavingsIcon
                              : moneyBagIcon
                          }
                          alt="lifetime-savings"
                        />
                        <div className="text-left ml-sm-2 align-self-start d-flex flex-column">
                          <div>
                            $
                            <span className="pre-checkout-card-number">
                              <CountUp
                                end={Math.round(saving.lifeTimeSavings || 0)}
                                duration={1}
                                separator=","
                                decimal="."
                              />
                            </span>
                          </div>
                          <div className="ml-1 ml-sm-0 align-self-center">
                            <Tooltip
                              html={
                                <CustomTooltip className="p-2">
                                  {lifetimeSavingsTip}
                                </CustomTooltip>
                              }
                              size="small"
                              position="bottom"
                              trigger={
                                width >= WindowBreakPoints.md
                                  ? "mouseenter"
                                  : "click"
                              }
                              animation="shift"
                              transitionFlip={false}
                            >
                              <div className="text-medium d-flex gap-1 align-items-center">
                                <span className="no-wrap">
                                  Est. Lifetime Savings
                                </span>
                                <span
                                  className="ml-05 line-height-1"
                                  style={{ marginTop: "1px" }}
                                >
                                  <TooltipIcon />
                                </span>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showFirstYearSavingsMode && (
                      <div className="col-12 col-sm-6 mb-2 p-0  d-flex justify-content-center">
                        <div className="d-flex align-items-center align-items-sm-end mr-2 justify-content-center flex-row">
                          <img
                            className="pre-checkout-card-img align-self-start mr-2"
                            src={monthlySavingsIcon}
                            alt="monthly-savings"
                          />
                          <div className="text-left ml-sm-2 align-self-start d-flex flex-column">
                            <div>
                              $
                              <span className="pre-checkout-card-number">
                                <CountUp
                                  end={firstYearMonthlySavings}
                                  duration={1}
                                  separator=","
                                  decimal="."
                                />
                              </span>
                              <span className="text-muted text-medium font-weight-300">
                                /monthly
                              </span>
                            </div>
                            <div className="text-medium ml-1 ml-sm-0 align-self-center">
                              Avg. 1st year savings
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2 w-100">
              <div className="card h-100 rounded px-2">
                <div className="pre-checkout-card-title">
                  Environmental Impact
                </div>
                <div className="divider" />
                <div className="row justify-content-between px-2 py-3">
                  <div className="col-12 col-sm-3 text-center mb-3 mb-sm-0">
                    <img
                      className="pre-checkout-card-img"
                      src={treeSeedlingsIcon}
                      alt="tree-seedlings"
                    />
                    <div>
                      <span className="pre-checkout-card-number">
                        <CountUp
                          end={treeSeedlings}
                          duration={1}
                          separator=","
                          decimal="."
                        />
                      </span>
                    </div>
                    <div className="text-center text-medium">
                      Tree Seedlings
                      <br />
                      Grown in 10 years
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 text-center mb-3 mb-sm-0">
                    <img
                      className="pre-checkout-card-img"
                      src={co2Icon}
                      alt="tree-seedlings"
                    />
                    <div>
                      <span className="pre-checkout-card-number">
                        <CountUp
                          end={co2Emmisions}
                          duration={1}
                          separator=","
                          decimal="."
                        />
                      </span>
                    </div>
                    <div className="text-center text-medium">
                      Tons of CO2
                      <br />
                      Emissions Avoided
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 text-center">
                    <img
                      className="pre-checkout-card-img"
                      src={oilBarrelIcon}
                      alt="oil-barrel"
                    />
                    <div>
                      <span className="pre-checkout-card-number">
                        <CountUp
                          end={gallonsOfGasoline}
                          duration={1}
                          separator=","
                          decimal="."
                        />
                      </span>
                    </div>
                    <div className="text-center text-medium">
                      Galons of Gasoline
                      <br />
                      Consumed
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2 w-100">
              <div className="card h-100 rounded px-2">
                <div className="d-flex justify-content-between">
                  <div className="pre-checkout-card-title">Service</div>
                </div>
                <div className="divider" />
                <div className="d-flex h-100 flex-column justify-content-center">
                  <div className="row py-2 px-4">
                    <div className="col text-center">
                      <img
                        className="pre-checkout-card-img"
                        src={equipmentWarrantyIcon}
                        alt="tree-seedlings"
                      />
                      <div className="text-medium text-center py-1">
                        Equipment
                        <br />
                        Warranty
                      </div>
                    </div>
                    <div className="col text-center">
                      <img
                        className="pre-checkout-card-img"
                        src={workmanshipWarrantyIcon}
                        alt="workmanship-warranty-icon"
                      />
                      <div className="text-medium text-center py-1">
                        Installation
                        <br />
                        Workmanship
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider" />
                <div className="row py-2 px-4">
                  <div className="col text-center">
                    <img src={tickIcon} alt="tick-icon" />
                  </div>
                  <div className="col text-center">
                    <img src={tickIcon} alt="tick-icon" />
                  </div>
                  <div className="text-center text-muted mt-3 mb-2 d-none d-sm-block text-medium">
                    See installation contract for full details
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="card h-100 rounded px-2">
            <div className="pre-checkout-card-title">Installation Details</div>
            <div className="divider" />
            <div className="row justify-content-between mx-1 py-3 px-2 px-sm-6 text-medium">
              <div className="d-flex flex-column col-6 col-md-3 mb-3">
                <img
                  className="pre-checkout-card-img align-self-start"
                  src={solarHouseIcon}
                  alt="solar-house"
                />
                <div className="text-start text-bold pt-1">System size</div>
                <div className="text-left">
                  <span className="text-start">{systemSize}</span>kW
                </div>
              </div>
              <div className="d-flex flex-column col-6 col-md-3 mb-3">
                <img
                  src={sustainableEnergyIcon}
                  className="pre-checkout-card-img align-self-start"
                  alt="sustainable-energy-icon"
                />
                <div className="text-left pt-1">
                  <div className="text-start text-bold">Est. production</div>
                  <div className="text-start">
                    <span className="text-start">30,000</span>
                    kWh
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column col-6 col-md-3 mb-3">
                <img
                  className="pre-checkout-card-img align-self-start"
                  src={installerIcon}
                  alt="solar-house"
                />
                <div className="text-left pt-1">
                  <span className="text-bold">Installer</span>
                </div>
                <div className="text-start">{installerName}</div>
              </div>
              <div className="d-flex flex-column col-6 col-md-3 ">
                <img
                  src={locationIcon}
                  className="pre-checkout-card-img align-self-start"
                  alt="location-icon"
                />
                <div className="d-flex flex-column justify-content-start text-left pt-1">
                  <div className="text-bold">Install address</div>
                  <div className="text-start">
                    {/* {getFormatedAddress(installationAddress)} */}
                    {splitedAddress[0]}
                    <br />
                    {splitedAddress[1] + "," + splitedAddress[2]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right row align-items-center mt-3 flex-sm-row-reverse">
          <div className="col-12 col-md-6 mb-1 mb-sm-0 px-0">
            <button
              type="submit"
              className="btn btn-primary font-weight-600 w-100 w-lg-50"
              onClick={() => {
                history.push(`/success-sunlight${search}`);
              }}
              disabled={demoMode}
            >
              Proceed to Sunlight
            </button>
          </div>
          <div className="col-12 col-md-6 mt-3 mt-md-0 d-flex px-0 justify-content-center justify-content-sm-start">
            <small className="text-muted">This offer is provided by</small>
            <img
              className="ml-05"
              src={sunlightFinancialIcon}
              alt="sunlight-financial"
            />
          </div>
        </div>
        <div className="text-start text-medium text-muted mt-4 mb-8">
          {footerInfo}
        </div>
      </div>
    </div>
  );
};

export default LoanSummary;
