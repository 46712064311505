import React, { FunctionComponent, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { getStripeSetupIntent } from "../../services/firebase";
import {
  downpaymentCalculationsAtom,
  stripeClientSecretAtom,
} from "../../state/atoms/global";
import { isAppLoadingAtom, isInDemoMode } from "../../state/atoms/ui";

import CheckoutForm from "./CheckoutForm";
import { MockStripeCheckout } from "./MockStripeCheckout";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

const Step4: FunctionComponent<{}> = () => {
  const demoMode = useRecoilValue(isInDemoMode);
  const downpaymentCalculations = useRecoilValue(downpaymentCalculationsAtom);
  const [isAppLoading, setIsAppLoading] = useRecoilState(isAppLoadingAtom);
  const [stripeClientSecret, setStripeClientSecret] = useRecoilState(
    stripeClientSecretAtom
  );

  const price = downpaymentCalculations[0].calculatedPlans.payNow;

  useEffect(() => {
    async function getSetupIntent() {
      if (demoMode) {
        setStripeClientSecret("mock-stripe");
        setIsAppLoading(false);
        return;
      }

      if (!stripeClientSecret) {
        try {
          setIsAppLoading(true);
          const setupIntent = await getStripeSetupIntent(price);
          setStripeClientSecret(setupIntent.client_secret);
        } catch (error) {
          console.error("Error getting Stripe setup intent", error);
        } finally {
          setIsAppLoading(false);
        }
      }
    }

    getSetupIntent();
  }, [stripeClientSecret]);

  if (isAppLoading || !stripeClientSecret) {
    return <></>;
  }

  if (demoMode) {
    return <MockStripeCheckout />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: stripeClientSecret }}
    >
      <CheckoutForm />
    </Elements>
  );
};

export default Step4;
