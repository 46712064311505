import { LOAN_TERMS } from "./plans";
import { LoanDetails } from "./proposal";

const multipliers: {
  [term: string]: {
    paymentMultiplier: number;
    savingsMultiplier: number;
    apr: number;
  };
} = {
  "12": {
    paymentMultiplier: 1.5,
    savingsMultiplier: 1.5,
    apr: 3.99,
  },
  "15": {
    paymentMultiplier: 1.2,
    savingsMultiplier: 1.25,
    apr: 2.99,
  },
  "20": {
    paymentMultiplier: 1.05,
    savingsMultiplier: 1.15,
    apr: 0.99,
  },
  "25": {
    paymentMultiplier: 0.9,
    savingsMultiplier: 1.1,
    apr: 1.99,
  },
};

export const generateSunlightResponse = ({
  fixedMonthlyPayment,
  saving,
  term,
}: {
  fixedMonthlyPayment: number;
  saving: number;
  term: LOAN_TERMS;
}): {
  result: { loan: LoanDetails };
  status: number;
} => {
  const { paymentMultiplier, savingsMultiplier, apr } = multipliers[term];
  const monthlyPayment = paymentMultiplier * fixedMonthlyPayment;
  return {
    result: {
      loan: {
        apr,
        monthlyPayment,
        escalatedMonthlyPayment: monthlyPayment * 1.68,
        paydownPercentage: 30,
        loanAmount: monthlyPayment * parseInt(term, 0) * 12 * 0.8,
        saving: {
          lifeTimeSavings: savingsMultiplier * saving,
          percentage: 0,
        },
      },
    },
    status: 200,
  };
};
