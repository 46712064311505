import React from "react";

const CustomTooltip: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <div
    className={`bg-white text-black m-0 p-1 max-w-200px text-start border border-secondary text-small rounded ${
      className ?? ""
    }`}
  >
    {children}
  </div>
);

export default CustomTooltip;
