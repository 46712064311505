import React, { FunctionComponent } from "react";

const PageNotFound: FunctionComponent<{}> = () => {
  return (
    <div className="mt-4 px-2 px-md-0 container-md">
      <h1>Oi vey...</h1>
      <h4 className="mt-4">Something went terribly wrong</h4>
    </div>
  );
};

export default PageNotFound;
