import { selector } from "recoil";
import dayjs from "dayjs";
import { isValidEmail, isValidPhoneNumber } from "../../services/utils";
import { Application } from "../../types/application";
import {
  customerDetailsAddressAtom,
  customerDetailsCityAtom,
  customerDetailsDateOfBirthAtom,
  customerDetailsEmailAtom,
  customerDetailsFirstNameAtom,
  customerDetailsLastNameAtom,
  customerDetailsPhoneNumberAtom,
  customerDetailsSsnAtom,
  customerDetailsStateAtom,
  customerDetailsZipAtom,
  step1DownpaymentPercentAtom,
  step1IncludeBatteryAtom,
  step1SelectedPlanAtom,
} from "../atoms/form";
import {
  contextElectricityAccelerationAtom,
  contextITCRateAtom,
  contextProposalAtom,
  contextProposalIdAtom,
} from "../atoms/global";
import { DealPlans } from "../../types/deal";

export const canSubmitStep1Selector = selector<boolean>({
  key: "canSubmitStep1Selector",
  get: ({ get }) => {
    const downpaymentPercent = get(step1DownpaymentPercentAtom);
    const selectedPlan = get(step1SelectedPlanAtom);

    if (
      downpaymentPercent === null ||
      selectedPlan === null ||
      downpaymentPercent === 100
    ) {
      return false;
    }

    return true;
  },
});

export const canSubmitStep2Selector = selector<boolean>({
  key: "canSubmitStep2Selector",
  get: ({ get }) => {
    const proposalId = get(contextProposalIdAtom);
    const firstName = get(customerDetailsFirstNameAtom);
    const lastName = get(customerDetailsLastNameAtom);
    const email = get(customerDetailsEmailAtom);
    const phoneNumber = get(customerDetailsPhoneNumberAtom);
    const address = get(customerDetailsAddressAtom);
    const city = get(customerDetailsCityAtom);
    const state = get(customerDetailsStateAtom);
    const zip = get(customerDetailsZipAtom);

    if (
      !proposalId ||
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !address ||
      !city ||
      !state ||
      !zip
    ) {
      return false;
    }

    if (!isValidEmail(email)) {
      return false;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      return false;
    }

    return true;
  },
});

export const isCustomerDetailsEmailValidSelector = selector<boolean>({
  key: "isCustomerDetailsEmailValidSelector",
  get: ({ get }) => {
    const customerDetailsEmail = get(customerDetailsEmailAtom);

    return !!(customerDetailsEmail && isValidEmail(customerDetailsEmail));
  },
});

export const isCustomerDetailsPhoneNumberValidSelector = selector<boolean>({
  key: "isCustomerDetailsPhoneNumberValidSelector",
  get: ({ get }) => {
    const customerDetailsPhoneNumber = get(customerDetailsPhoneNumberAtom);

    return !!(
      customerDetailsPhoneNumber &&
      isValidPhoneNumber(customerDetailsPhoneNumber)
    );
  },
});

export const isCustomerDetailsBirthDateValidSelector = selector<boolean>({
  key: "isCustomerDetailsBirthDateValidSelector",
  get: ({ get }) => {
    const customerDetailsBirthDate = get(customerDetailsDateOfBirthAtom);
    if (!customerDetailsBirthDate) {
      return true;
    }
    const minimumBirthDateToApply = dayjs()
      .subtract(18, "year")
      .format("YYYY-MM-DD");
    const maximumBirthDateToApply = dayjs()
      .subtract(100, "year")
      .format("YYYY-MM-DD");

    return (
      dayjs(customerDetailsBirthDate).isBefore(minimumBirthDateToApply) &&
      dayjs(customerDetailsBirthDate).isAfter(maximumBirthDateToApply)
    );
  },
});

export const isCustomerDetailsSsnValidSelector = selector<boolean>({
  key: "isCustomerDetailsSsnValidSelector",
  get: ({ get }) => {
    const customerDetailsSsn = get(customerDetailsSsnAtom);
    if (!customerDetailsSsn) {
      return true;
    }

    const regexp = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;

    return regexp.test(customerDetailsSsn);
  },
});

export const applicationSelector = selector<Application>({
  key: "applicationSelector",
  get: ({ get }) => {
    const application: Application = {
      proposalId: get(contextProposalIdAtom) || "",
      firstName: get(customerDetailsFirstNameAtom) || "",
      lastName: get(customerDetailsLastNameAtom) || "",
      email: get(customerDetailsEmailAtom) || "",
      phoneNumber: get(customerDetailsPhoneNumberAtom) || "",
      location: {
        address: get(customerDetailsAddressAtom) || "",
        city: get(customerDetailsCityAtom) || "",
        state: get(customerDetailsStateAtom) || "",
        zip: get(customerDetailsZipAtom) || "",
      },
      birthDate: new Date(get(customerDetailsDateOfBirthAtom) || 0),
      SSN: get(customerDetailsSsnAtom) || "",
      selectedPlan: get(step1SelectedPlanAtom) || DealPlans.FIXED,
      downPayment: (get(step1DownpaymentPercentAtom) || 0) * 0.01,
      addBattery: get(step1IncludeBatteryAtom) || false,
    };
    return application;
  },
});

export const isCustomerDetailsPhoneNumberChangedSelector = selector<boolean>({
  key: "isCustomerDetailsPhoneNumberChangedSelector",
  get: ({ get }) => {
    const customerDetailsNewPhoneNumber = get(customerDetailsPhoneNumberAtom);
    const contextProposal = get(contextProposalAtom);
    const phoneNumber = contextProposal?.prospect?.phoneNumber;
    return customerDetailsNewPhoneNumber !== phoneNumber;
  },
});

export const isCustomerDetailsEmailChangedSelector = selector<boolean>({
  key: "isCustomerDetailsEmailChangedSelector",
  get: ({ get }) => {
    const customerDetailsNewEmail = get(customerDetailsEmailAtom);
    const contextProposal = get(contextProposalAtom);
    const email = contextProposal?.prospect?.email;

    return customerDetailsNewEmail !== email;
  },
});

export const getPlansTooltipMessagesSelector = selector<{
  buy: string;
  lease: string;
  loan: string;
}>({
  key: "getPlansTooltipMessagesSelector",
  get: ({ get }) => {
    const contextProposal = get(contextProposalAtom);
    const locationState = contextProposal?.prospect?.location?.state;
    const systemDetailsUtilityRate =
      contextProposal?.deal.systemDetails?.utilityRate.toLocaleString("en", {
        useGrouping: true,
      });

    const ITCRatePercentage = get(contextITCRateAtom) * 100;

    const electricityAccelerationPercentage =
      get(contextElectricityAccelerationAtom) * 100;

    return {
      buy:
        locationState && locationState === "NY"
          ? `Based on the current utility rate of ¢${systemDetailsUtilityRate}, ${ITCRatePercentage}% federal state incentive (ITC), NY state tax credit use, and estimated electricity cost increase of ${electricityAccelerationPercentage}%.`
          : `Based on the current utility rate of ¢${systemDetailsUtilityRate}, ${ITCRatePercentage}% federal state incentive (ITC), and estimated electricity cost increase of ${electricityAccelerationPercentage}%.`,
      lease: `Based on the current utility rate of ¢${systemDetailsUtilityRate}, and estimated electricity cost increase of ${electricityAccelerationPercentage}%.`,
      loan: `Based on the current utility rate of ¢${systemDetailsUtilityRate}, ${ITCRatePercentage}% federal state incentive (ITC), and estimated electricity cost increase of ${electricityAccelerationPercentage}%.`,
    };
  },
});
