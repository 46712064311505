import React, { FunctionComponent, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const StartFresh: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    history.push(`/go-solar${search}`);
  }, []);

  return <></>;
};

export default StartFresh;
