import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useLottie } from "lottie-react";

import splashAnimation from "../assets/animations/splash.json";

import { contextProposalAtom } from "../state/atoms/global";

const AnimationComponent = () => {
  const lottieOptions = {
    animationData: splashAnimation,
    loop: true,
    autoplay: true,
    height: 100,
  };

  const Lottie = useLottie(lottieOptions);

  useEffect(() => {
    Lottie.setSpeed(1.5);
  }, []);

  return Lottie.View;
};

const StartSplash: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const contextProposal = useRecoilValue(contextProposalAtom);
  const [animation, setAnimation] = useState("rhino_animate__fadeIn");

  useEffect(() => {
    const timerAnimation = setTimeout(() => {
      setAnimation("rhino_animate__fadeOut");
    }, 3500);
    const timerRedirect = setTimeout(() => {
      history.push(`/step-1${search}`);
    }, 4000);

    return () => {
      clearTimeout(timerAnimation);
      clearTimeout(timerRedirect);
    };
  }, []);

  return (
    <>
      <div
        className={`text-center px-3 mt-2 rhino_animate__animated ${animation}`}
      >
        <h1 className="m-0 font-weight-600">You&apos;re about to go solar!</h1>
      </div>
      <div className={`text-center rhino_animate__animated ${animation}`}>
        <div
          className={`m-auto w-100 w-md-50 max-w-400px`}
          style={{
            marginTop: "-20rem",
            marginBottom: "-20rem",
          }}
        >
          <AnimationComponent />
        </div>
      </div>
      <div className={`text-center rhino_animate__animated ${animation}`}>
        <div className="mb-3">
          {contextProposal?.installer.name} just sent you a proposal
        </div>
        {contextProposal?.installer.logo && (
          <img
            className="installer-logo grayscale-1"
            src={contextProposal.installer.logo}
            alt="installer-logo"
          ></img>
        )}
      </div>
    </>
  );
};

export default StartSplash;
